:root {
    --#{$prefix}headerHeight: 8rem;    

	// **********************************************************
	// COLORS
	@each $color, $value in $theme-colors {
		--#{$prefix}#{$color}: 		#{$value};
	}
	@each $color, $value in $theme-colors-rgb {
		--#{$prefix}#{$color}-rgb: 	#{$value};
	}
	
	--#{$prefix}body-color:	  		#{$body-color};
	--#{$prefix}body-color-rgb: 	#{to-rgb($body-color)};
	--#{$prefix}body-bg: 			#{$body-bg};
	--#{$prefix}body-bg-rgb: 		#{to-rgb($body-color)};
	--#{$prefix}link-color:			#{$primary};

	--#{$prefix}facebook:        	#4267B2;
	--#{$prefix}twitter:         	#111111;
	--#{$prefix}whatsapp:        	#128C7E;
	--#{$prefix}linkedin:        	#0077B5; 

	--#{$prefix}red:							#E40166;
	--#{$prefix}orange:						#fb825c;
	--#{$prefix}yellow:						#F6A808;
	--#{$prefix}olive:						#B5CC18;
	--#{$prefix}green:						#72bc75;
	--#{$prefix}teal:							#517776;
	--#{$prefix}violet:						#6435C9;
	--#{$prefix}purple:						#A333C8;
	--#{$prefix}pink:							#E40166;
	--#{$prefix}brown:						#826A6A;
	--#{$prefix}grey:							#68778D;
	--#{$prefix}black:						#252525;

	--#{$prefix}squaremore-bg: 		var(--#{$prefix}white);
	--#{$prefix}squaremore-color: 	var(--#{$prefix}primary);
	--#{$prefix}bg-slide: 			none; 

	--#{$prefix}hamburger-color:	var(--#{$prefix}white);
	--#{$prefix}nav-link-color:		var(--#{$prefix}primary);
	--#{$prefix}nav-link-hover-color: var(--#{$prefix}dark);

	--#{$prefix}type-bg:			#A8514C;
	--#{$prefix}type-color:			#{$white};

	--#{$prefix}slideButtonBg:		transparent;
	--#{$prefix}slideButtonColor:  	var(--#{$prefix}primary);
	--#{$prefix}slideButtonBg-hover:transparent;
	--#{$prefix}slideButtonColor-hover:var(--#{$prefix}secondary);

	--#{$prefix}bgMenu:				var(--#{$prefix}white);

	
	// **********************************************************
	// SIZES
	--#{$prefix}fs-scale: 1;
	--#{$prefix}body-font-size:		#{$font-size-base};
	
	// **********************************************************
	// BUTTONS
	--#{$prefix}btn-font-size: 		#{$btn-font-size};
	

	// **********************************************************
	// BORDERS
    // scss-docs-start root-border-var
	--#{$prefix}border-width: 		#{$border-width};
	--#{$prefix}border-style: 		#{$border-style};
	--#{$prefix}border-color: 		#{$border-color};
	--#{$prefix}border-color-translucent: #{$border-color-translucent};

	--#{$prefix}border-radius: 		#{$border-radius};
	--#{$prefix}border-radius-sm: 	#{$border-radius-sm};
	--#{$prefix}border-radius-lg: 	#{$border-radius-lg};
	--#{$prefix}border-radius-xl: 	#{$border-radius-xl};
	--#{$prefix}border-radius-2xl:	#{$border-radius-2xl};
	--#{$prefix}border-radius-pill: 	#{$border-radius-pill};
	// scss-docs-end root-border-var

	// **********************************************************
	// IMAGES
  	--#{$prefix}bgHeader: url(img/bg-header.jpg);
  	--#{$prefix}bgHeader-xs: url(img/bg-header-xs.jpg);
  	--#{$prefix}bgHeader-md: url(img/bg-header-md.jpg);
  	--#{$prefix}bgHeader-lg: url(img/bg-header-lg.jpg);

		--#{$prefix}bgBanners: url(img/bg-banners.jpg);

	 
}
  
