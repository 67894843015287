@media print {

	body {
		display: block;
	}
	
	
	// **********************************************************
	// COMPONENTS
	.slideControls {
		display: none !important;
	}
	.ui.segments .segment, .ui.segment {
		font-size: 10pt;
	}
	.pageDescription {
		font-size: 12pt;
	}

	// **********************************************************
	// HEADER
	.bgArc {
		min-height: 0;
		padding: 1rem;
		&::before {
			content: none;
		}
	}
	body.headerNegativeBottom .innerHeader {
		padding-bottom: 1rem;
	}

	// **********************************************************
	// LISTS
	.newsPage {
		&__img {
			margin: 0 0 1rem 0;
			max-width: 33%;
		}
	}
	.container.news, .container.events {
		.ui.grid > [class*="twelve wide"].column {
			width: 33% !important;
		}
	}

	// **********************************************************
	// NEWS
	.newsItem.ui.card {
		break-inside: avoid;
	}

	// **********************************************************
	// EVENTS
	.eventPage__img__wrapper, .eventInfo.ui.segment {
		margin-top: 0;
	}
	
	// **********************************************************
	// SLIDESHOW
	.slideItem {
		height: auto;
		&__image{
			height: auto;
		}
		&__text {
			height: auto;
		}
	}

	// **********************************************************
	// FOOTER
	.portalFooter{
		display: none;
	}
}