// **********************************************************
// RELATED CONTENT
.relatedContent {
    --#{$prefix}related-bg-color: var(--#{$prefix}primary);
    --#{$prefix}related-text-color: var(--#{$prefix}white);
    --#{$prefix}related-link-color: var(--#{$prefix}white);
    --#{$prefix}related-icon-color: var(--#{$prefix}primary);
    --#{$prefix}link-color: var(--#{$prefix}white);
    --#{$prefix}link-hover-color: var(--#{$prefix}secondary);

	&.ui.basic.segment {
		color: var(--#{$prefix}related-text-color);
		background: url(../img/rodonetes-related.jpg) no-repeat top right / cover var(--#{$prefix}related-bg-color);
		background-blend-mode: multiply;
        margin-bottom: 0;
	}
	&__title {
		&.ui.header {
			color: var(--#{$prefix}related-text-color);
		}
	}
	&__section {
		&.ui.segment {
			padding-left: 0;
			padding-right: 0;
		}
	}
    .userBlocks + & {
        margin-top: 5rem;
    }
}

// **********************************************************
// RELATED DOCUMENT & LINK
.relatedItem {
    transition: $transition-base;
    min-height: 4rem;
    display: grid;
    grid-template-columns: 4rem 1fr;
    grid-template-rows: auto auto;
    gap: 0 1rem;
    color: var(--#{$prefix}related-link-color);
    text-decoration: none;
    width: 100%;
    font-size: calc(var(--#{$prefix}body-font-size) * var(--#{$prefix}fs-scale));
    line-height: $line-height-sm;
	margin-bottom: 1.5rem;
    &__icon {
        width: 4rem;
        height: 4rem;
        background-color: var(--#{$prefix}related-text-color);
        color: var(--#{$prefix}related-icon-color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        grid-column: 1 / 2;
        grid-row: 1/3;
        svg {
			height: 1.5rem;
		}
    }
    &__text {
        font-weight: $font-weight-bold;
        grid-column: 2/3;
        grid-row: 1/2; 
		&-links {
			font-weight: $font-weight-normal;
			margin-top: 0;
			align-self: center;
			grid-row: 1 / 3;
		}
    }
    &__size {
        opacity: .8;
        font-size: calc(.875em * var(--#{$prefix}fs-scale));
        grid-column: 2/3;
        grid-row: 2/3;
        margin-top: 0.25rem;
    }
    &:hover, &:focus {
        text-decoration: none; 
        color: var(--#{$prefix}tertiary);
        .relatedItem__icon {
            background-color: var(--#{$prefix}secondary);
            color: var(--#{$prefix}primary);
            border: 1px solid var(--#{$prefix}secondary);
        }
    }
}


// **********************************************************
// RELATED MEDIA
.relatedMedia {    
    --#{$prefix}lightboxColor: var(--#{$prefix}white);
    .lightBoxWrapper {
        display: grid;
        gap: 2rem;
        
        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);    
        }
        // 1280 pixels - 80em
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(3, 1fr);
        }
         
    }
    .lightBoxItem {
        &__img {
            transition: $transition-base;
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
        &:hover, &:focus-within {
            .lightBoxItem__img {
                opacity: .66;
            }
        }
        &__link.ui.button {
            &:hover, &:focus {
                text-decoration: underline;
                background-color: transparent;
                color: var(--#{$prefix}--bs-secondary)
            }
        }
    }
	// &.ui.card {
	// 	margin-bottom: 1.5rem;
	// 	width: 100%;
	// 	border-radius: 0;
	// 	box-shadow: none;
	// 	background-color: transparent;
	// }   
    // &__img {
    //     transition: $transition-base;
    //     border: 1px solid transparent;
	// 	width: 100%;
	// 	height: auto;
	// 	.ui.card > & {
	// 		border-radius: 0 !important;
	// 	}
    // }
    // &__link {
    //     display: block;
    //     text-decoration: none; 
    // }
    // &__text {
	// 	.ui.card > &.content {
	// 		font-weight: var(--bs-body-font-weight);
	// 		font-family: $headings-font-family;
	// 		margin-top: 0.5rem;
	// 		font-size: calc(var(--bs-body-font-size) * .875);
	// 		border-top: none;
	// 		border-radius: 0;
	// 		font-size: $font-size-sm;
	// 		padding:0;
	// 	}
    // }
	// &__icon {
	// 	color: var(--#{$prefix}white);
	// 	height: 1rem;
	// 	width: auto;
	// 	position: absolute;
	// 	right: 1rem;
	// 	top: 1rem;
	// 	z-index: $zindex-flat + 2;
	// }
    // &:hover, &:focus-within {
    //     .relatedMedia__img {
    //         border-color:var(--bs-secondary);
    //     }
    // }
}
