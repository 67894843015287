// **********************************************************
//  INFO BOXES
.infogBlock {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 62.5rem;
  &__item {
    flex: 1 1 0;
    font-family: $headings-font-family;
    margin-bottom: 0;
    background-color: var(--#{$prefix}light);
    color: var(--#{$prefix}primary);
    padding: 1.5rem;
    text-align-last: left;
    strong {
      display: block;
      font-size: 2rem;
      line-height: 1.5;
      font-weight: $font-weight-bold;
    }
    span {
      display: block;
      font-size: $font-size-sm;
      line-height: 1.1;
    }
  }

  // 768 pixels - 48em
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
.mainInfoData {
  --#{$prefix}secondary:--#{$prefix}primary;
  --#{$prefix}tertiary: --#{$prefix}primary;
  .ui.statistics .statistic > .label, .ui.statistic > .label {
    font-weight: $font-weight-normal;
    text-transform: none;
  }
  .ui.three.statistics {
    flex-direction: column;
    align-items: stretch;
    & > .statistic {
      background-color: var(--#{$prefix}light);
      color:var(--#{$prefix}primary);
      padding: 1.5rem;
      font-family: $headings-font-family;
      align-items: flex-start;
      .value {
        font-weight: $font-weight-bold;
        display: block;
        color:var(--#{$prefix}primary);
      }
      .text-center {
        text-align: left !important;
      }
    }
    p {
      line-height: 1;
    }
    .slate + .slate {
      margin-top: 0.5rem;
    }
  }

  // 576 ixels - 36em
  @include media-breakpoint-up(sm) {    
    .ui.three.statistics {
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      & > .statistic {
        flex: 1 1 0;
        margin-bottom: 0;
        & + .statistic {
          margin-left: 1em;
          // 1280 pixels - 80em
          @include media-breakpoint-up(xl) {
            margin-left: 2em;
          }
        }
      }
    }
  }
}

// **********************************************************
// CHARTS
.wrapperInfographic {
  --sa-chartColor: var(--#{$prefix}tertiary);

  .graphWrapper { 
    font-size: $font-size-xs;
    margin-bottom: 4rem;
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
      margin-bottom: 8rem;
    }
  }
  .recharts-legend-wrapper {
    transform: translateY(1.5rem);
  }
  .recharts-default-legend {
    display: flex;
    flex-wrap: wrap;
    & > * {
      flex: 1 1 0;
      white-space: nowrap;
      text-align: left;
    }
  }
  .recharts-legend-item-text {
    color: var(--#{$prefix}primary) !important;
    font-size: $font-size-sm;
  }
}
.researchList {
  &__item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 0.25rem;
    &:nth-child(1) .researchList__definition {color: #6A7A9A;}
    &:nth-child(2) .researchList__definition {color: #636463;}
    &:nth-child(3) .researchList__definition {color: #94514D;}
    &:nth-child(4) .researchList__definition {color: #A5A4A4;}
    &:nth-child(5) .researchList__definition {color: #DC832F;}
    &:nth-child(6) .researchList__definition {color: #815C5B;}
    &:nth-child(7) .researchList__definition {color: #91A53F;}
    &:nth-child(8) .researchList__definition {color: #274478;}
    &:nth-child(9) .researchList__definition {color: #9D4916;}
    &:nth-child(10) .researchList__definition {color: #E6B029;}
    &:nth-child(11) .researchList__definition {color: #d1a83d;}
  }
  &__term {
    flex-grow: 1;
  }
  &__definition {
    width: 4.5em;
    flex-shrink: 0;
    display: flex;
    &::before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: .25rem;
      background-color: currentColor;
      display: block;
      margin-right: 0.5rem;
    }
  }
}
