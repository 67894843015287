// **********************************************************
// NAVIGATION
.btnLandingToggle {
	&.ui.dropdown{
		&.button {
			height: 2.5rem;
			width: 2.5rem;
			background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 20'%3E%3Cpath d='M0,20V17.142H15V20Zm0-8.571V8.571H24v2.858ZM0,2.858V0H24V2.858Z' fill='%23fff'/%3E%3C/svg%3E") no-repeat center center transparent !important;
			border-radius: 0;
		}
		& > .menu {
			min-width: 13rem; //208px
			left: auto;
			right: 0;
			font-family: $headings-font-family;
			box-shadow: none;
			border: none;
			.item {			
				color: var(--#{$prefix}nav-link-color);
				text-decoration: none;
				&:hover, &:focus {
					color: var(--#{$prefix}nav-link-hover-color);
				}
			}
		}
		.mnPrincipal__second{
			&.menu {
				position: static;
				box-shadow: none;
				border: none;
				& > .header {
					padding: 0;
				}
			}
			&__wrapper {
				flex-direction: column;
			}
			a.item {
				padding: .5rem;
				font-size: .875rem;
				display: block;
			}
			&__list {
				margin-bottom: 1rem;
			}
		}
	}
	& > .divider.text, & > .icon {
		position: absolute;
		left: -1999em;
		height: 1px;
		width: 1px;
		overflow: hidden;
	}
}
body.landing {

	// **********************************************************
	// HEADER
	.bgArc {
		min-height: 30rem;
	}
}
	
// **********************************************************
// LANDING JOBOFFERS
.jobOfferCard {
	--#{$prefix}translate: 0 , 50%;
	&.ui.card {
		padding: 1.5rem;
		background-color: var(--#{$prefix}light);	
		box-shadow: none;
		border-radius: 0;
		& > .content {
			border-top: 0;
			padding-left: 0;
			padding-right: 0;

		}
		& > .extra {
			color: rgba(var(--#{$prefix}dark-primary-rgb), .7);
			display: flex;
			flex-wrap: wrap; 
			flex-direction: column;
		}
	}
	&__title {
		font-size: $h5-font-size;
		font-weight: $font-weight-bold;
		margin-bottom: 0;
	}
	&__link {
		text-decoration: none;
		color: var(--#{$prefix}primary);
		&::before {
            content: '';
            display: block;
            position: absolute;
            width: 3.5rem;
            height: 3.5rem;
            bottom: 0;
            right: 1.5rem;
            transform: translateY(50%);
            z-index: $zindex-flat + 3;
        }
	}
	&__meta  {
		font-size: $font-size-sm;
		
	}
	&__desc {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}
	.squareMore {
		transition: $transition-base;
		position: absolute;
		bottom: 0;
		right: 1.5rem;
		transform: translate(var(--#{$prefix}translate));
		z-index: $zindex-flat;
	}
	&:hover, &:focus-within{
        --#{$prefix}translate: .5rem , 50%;
        --#{$prefix}squaremore-bg: var(--#{$prefix}primary);
        --#{$prefix}squaremore-color: var(--#{$prefix}secondary);
    }
}


