// **********************************************************
// NEWS in newsList
.newslisting {
	--#{$prefix}-newsItem-bg: var(--#{$prefix}white);
}

.newsItem { 
	&.ui.card {
		border-radius: 0;
		box-shadow: none;
		background-color: var(--#{$prefix}-newsItem-bg, var(--#{$prefix}white) );
		.content {
			border-top: none;
		}
	}
	&-noImage.ui.card {
		transition: $transition-base;
		aspect-ratio: 1 / 1;
		border-top: 1px solid var(--#{$prefix}secondary);
		border-bottom: 1px solid var(--#{$prefix}secondary);
		padding-top: 1rem;
		& > .image {
			background-color: transparent;
		}
		&:hover, &:focus-within {
			background-color: var(--#{$prefix}secondary);
			.newsItem__img__wrapper, .newsItem__img__wrapper.image.ui {
				background-color: var(--#{$prefix}secondary);
			}
		}
		&.newsItem-withShare {
			padding-top: calc(2rem + 2.5rem);
		}
		&:has(.newsItem__img) {
			border-top: none;
			padding-top: 0;
			.newsItem__type {
				position: absolute;
				transform: translateY(50%);
			}
		}
	}
    &__img {
		transition: $transition-base;
		aspect-ratio: 1 / 1;
		object-fit: cover;
		width: 100%;
		height: auto;
		.ui.card &__wrapper {
			border-radius: 0 !important;
		}
	}
	&__title {
		font-size: calc( 1.25rem * var(--#{$prefix}fs-scale) );
		font-weight: $font-weight-normal;
		display: block;
		line-height: $line-height-sm;
	}
	&__link {
		text-decoration: none;
		color: var(--#{$prefix}primary);
		&:hover, &:focus {
			text-decoration: underline;
			text-decoration-color: var(--#{$prefix}secondary);
		}
		&:focus {
			outline: 1px dotted var(--#{$prefix}primary);
		}
	}
	&__text {
		position: relative;
		@supports(aspect-ratio: 1/1) {
			position: static;
		}
		.newsItem.card &.content {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__type{
		&.ui.labels .ui.label {
			font-weight: $font-weight-normal;		
			border-radius: 10rem;
			background-color: var(--#{$prefix}type-bg);
			color: var(--#{$prefix}type-color);
			text-transform: uppercase;			
			font-size: calc( 0.8rem * var(--#{$prefix}fs-scale) );
		}
		.newsItem & {
			z-index: $zindex-flat + 1;
			position: absolute;
			bottom: 0;
			right: 1.5rem;
			transform: translateY(50%);
		}
		.newsItem-noImage & {
			position: static;
			text-align: right;
			transform: none;
		}
	}
	&__date {
		font-weight: $font-weight-bold;
		font-size: calc( 0.95rem * var(--#{$prefix}fs-scale) );
		color: var(--#{$prefix}body-color);
		margin-bottom: 0.5rem;
		display: block;
	}	
	&:hover, &:focus-within {
		.newsItem__img {
			mix-blend-mode: multiply;
			opacity: .33;
			&__wrapper, &__wrapper.image.ui {
				background-color: var(--#{$prefix}secondary);
			}
		}
	}
}


// **********************************************************
// NEWS PAGE
.newsPage {
	&__img {
		position: relative;
		margin: -6.5rem 0 2.5rem 0;
		z-index: $zindex-flat;
		.documentImage {
			width: 100%;
			height: auto;
		}
		&__caption {
			color: var(--#{$prefix}primary);
			font-family: $headings-font-family;
			font-size: $font-size-sm;
			line-height: $line-height-sm;
			margin-top: .5rem;
		}
	}
	&__location {
		font-size: $font-size-sm;
		color: var(--#{$prefix}primary);
		margin-bottom: 1rem;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
	}
	&__aside {
		background-color: var(--#{$prefix}light);
		color: var(--#{$prefix}primary);
		margin-top: 0;		
		padding: 1rem;
		margin-bottom: 3rem;
		&__title.ui.header {
			font-size: $h4-font-size;
			color: var(--#{$prefix}primary);
			margin-bottom: .5rem;
		}
		.ui.label {
			text-decoration: none;
			text-transform: uppercase;
			background-color: var(--#{$prefix}type-bg);
			color: var(--#{$prefix}type-color);
			border-radius: 100rem;
		}	
		// 992 pixels - 62em
		@include media-breakpoint-up(lg) {
			margin-top: -6.5rem;
			z-index: $zindex-flat;
			position: relative;
		}	
	}
}
.innerHeader time {
	font: $font-weight-bold #{$h5-font-size} / #{$headings-line-height} $headings-font-family;
	color: var(--#{$prefix}white);
	text-transform: uppercase;
	letter-spacing: .125em;
}

// **********************************************************
// PAGE CONTENT
body.contenttype-news-item {
	.content-area.ui.segment.basic  {
		// 768 pixels - 48em
		@include media-breakpoint-up(lg) {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='948.265' height='764.668' viewBox='0 0 948.265 764.668'%3E%3Cdefs%3E%3ClinearGradient x1='0.626' y1='1.099' x2='0.379' y2='0.522' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='1' stop-color='%23f1f1f1'/%3E%3C/linearGradient%3E%3CclipPath %3E%3Cpath d='M15873.693,525.319a770.178,770.178,0,0,1-154.111-15.537,760.668,760.668,0,0,1-143.542-44.558,764.735,764.735,0,0,1-129.9-70.5,770.185,770.185,0,0,1-113.169-93.376,769.69,769.69,0,0,1-93.377-113.174,764.794,764.794,0,0,1-70.507-129.892,761.02,761.02,0,0,1-44.558-143.542A770.213,770.213,0,0,1,15109-239.348h347.586c.021,229.98,187.133,417.082,417.106,417.082a417.724,417.724,0,0,0,95.688-11.076,414.707,414.707,0,0,0,87.884-31.536V503.137c-9.762,2.407-19.674,4.643-29.463,6.646A770.115,770.115,0,0,1,15873.693,525.319Z' transform='translate(-14697.266 487)' fill='url(%23linear-gradient)'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-411.735 -247.652)' clip-path='url(%23clip-path)'%3E%3Cpath d='M15873.693,525.319a770.178,770.178,0,0,1-154.111-15.537,760.668,760.668,0,0,1-143.542-44.558,764.735,764.735,0,0,1-129.9-70.5,770.185,770.185,0,0,1-113.169-93.376,769.69,769.69,0,0,1-93.377-113.174,764.794,764.794,0,0,1-70.507-129.892,761.02,761.02,0,0,1-44.558-143.542A770.213,770.213,0,0,1,15109-239.348h347.586c.021,229.98,187.133,417.082,417.106,417.082a417.724,417.724,0,0,0,95.688-11.076,414.707,414.707,0,0,0,87.884-31.536V503.137c-9.762,2.407-19.674,4.643-29.463,6.646A770.115,770.115,0,0,1,15873.693,525.319Z' transform='translate(-14697.266 487)' fill='%23f1f1f1'/%3E%3C/g%3E%3C/svg%3E%0A"), url('../img/rodonetes.jpg');
			background-repeat: no-repeat, no-repeat;
			background-position: right top, -18rem calc(100% + 25rem);
			background-size: 67%, auto;
			background-color: var(--#{$prefix}-body-bg);
			margin-top: 0;
			margin-bottom: 0;
			padding-top: calc(1rem + 1em);
			padding-bottom: calc(1rem + 1em);
		}
	}
	&.hasRelatedContent .content-area.ui.segment.basic  {
		padding-bottom: 0 !important;
	}
}