$prefix: 'sa-';
.lightBoxWrapper {
 

  &.wrapper img {
    cursor: pointer;
  }

  /* modal */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 27, 0.66);
    backdrop-filter: blur(13px);
    --webkit-backdrop-filter: blur(13px);


    display: flex;
    align-items: center;
    z-index: 3000;
  }
  .overlay img {
    display: block;
    max-width: calc(100% - 10rem);
    height: auto;
    width: auto;
    // max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    object-fit: contain;
  }
  .overlay > .ui.button.dismiss {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 30px;
    color: var(--sa-white);
    z-index: 999;
    cursor: pointer;
    margin: 0;
    width: 45px;
    text-align: center;
  }
  .overlay-arrows_left,
  .overlay-arrows_right {
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    z-index: 999;
    &.ui.basic.button.inverted {
      display: flex;
      font-size: 1.5rem;
      background-color: rgba(var(--#{$prefix}body-color-rgb), .66) !important;
      border-radius: 25px;
      margin: 0;
      &:hover, &:focus {
        background-color: var(--#{$prefix}secondary) !important;
        color: var(--#{$prefix}primary) !important;
      }
    }
  }
  .overlay-arrows_left {
    left: .5rem;

  } 
  .overlay-arrows_right {   
    right: .5rem;
  }

  @media screen and (min-width: 1280px) {
    .overlay img {
      display: block;
      max-width: 80%;
    }
    .overlay-arrows_left {
      left: 1.5rem;
    } 
    .overlay-arrows_right {   
      right: 1.5rem;
    }
  }
}

.lightBoxItem {
  position: relative;
  &.ui.card {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }
  &__img {
    width: 100%;
    height: auto;
  }
  &__caption {
    &.content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  &__link {
    &.ui.button {
      display: block;
      color: var(--#{$prefix}lightboxColor, var(--#{$prefix}white));
      background-color: transparent;
      border-radius: 0;
      border: none;
      box-shadow: none;
      padding: 0;
      font-size: calc(0.875rem * var(--#{$prefix}fs-scale));
      font-weight: 400;
    }
    &::before {
      content: '';
      display: block;
      inset: 0;
      position: absolute;
    }
  }
}