// **********************************************************
// SHARE BUTTON
.socialShare {
	&.ui.dropdown {
		position: absolute;
		z-index: $zindex-flat + 2;
		top: 1rem;
		right: 1rem;
		& > .divider.text {
			@include visually-hidden();
		}
		.headerHasSocialShare > & {
			position: relative;
			top: auto;
			right: 0;
		}
	}
	&.ui.button {
		background-color: var(--#{$prefix}white);
		color: var(--#{$prefix}primary);
		box-shadow: $box-shadow-sm;
		width: 2.5rem;
		height: 2.5rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 1px;
		border-radius: 1.25rem !important;
		&:hover, &:focus {
			background-color: var(--#{$prefix}primary);
			color: var(--#{$prefix}white);
		}
		& > .icon {
			font-size: 1.5rem;
			height: 1.5rem;
			width: 1.5rem;
			line-height: 1;
		}
	}
	.header .icon {
		font-size: 1rem;
		height: 1rem;
		line-height: 1;
	}
	&__link {
		display: flex;
		text-decoration: none;
		&__icon {
			width: 1.25rem;
			flex-shrink: 0;
			margin-right: .5rem;
			svg {
				height: 1em;
				width: auto;
			}
		}
		&__text {
			font-size: $font-size-sm;
			text-transform: uppercase;
			font-weight: $font-weight-normal;
			font-family: $headings-font-family;
		}
		&:hover {
			text-decoration: none;
		}
	}

}