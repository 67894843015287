// **********************************************************
// LAYOUT
body.frontpage {
    .segment.breadcrumbs { 
        display: none;
    }
    .ui.basic.segment.content-area {
        margin-top: 0;
        padding-top: 0;
    }
}

.homeSectionLayout {
    .slider-wrapper {
        --slide-size: 100%;
        --slide-spacing: 1.5rem;
    }
     
    .slideControls {
        margin: 0 0 1rem auto;
        display: flex;
        justify-content: flex-end;
    }

    @include media-breakpoint-up(sm) {
        .slider-wrapper {
            --slide-size: 24rem; 
        }
        .slider__viewport {
            max-width: calc(100vw - 1rem); 
        }
        .news-slider, .events-slider{
            margin-right: -1em;
        }
    }
    // 768 pixels - 48em
    @include media-breakpoint-up(md) {
        .slider__viewport { 
            body.has-toolbar & {
                max-width: calc(100vw - 1rem - 80px);
            }
            body.has-toolbar-collapsed & {
                max-width: calc(100vw - 1rem - 20px);
            }
        }
    }
    // 1280 pixels - 80em
    @include media-breakpoint-up(xxl) {
       & + & {
        margin-top: 6rem;
       }
    }

    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        --containerWidth: 927px;
        display: grid;
        grid-template-columns: 1fr calc(var(--containerWidth) * 0.25) calc(var(--containerWidth) * 0.75) 1fr;
        gap: 0;
        .secCol1 {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            padding: 3.5rem 1.5rem 0 0;
        }
        .secCol2 {
            grid-column: 3 / 5;
            grid-row: 1 / 2;
            position: relative;
            z-index: $zindex-flat + 1;
        }
        .slideControls {
            margin-right: calc( 50vw - (var(--containerWidth) / 2) );
        }
    }
     
    @media (min-width: 1200px) {
        --containerWidth: 1119px;
    }

    @media (min-width: 1600px) {
        --containerWidth: 1520px;
    }
    
    body.has-toolbar & {
        @include media-breakpoint-up(lg) {
            --containerWidth: #{927px - 80px};
        }
        @media (min-width: 1200px) {
            --containerWidth: #{1119px - 80px};
        }
    
        @media (min-width: 1600px) {
            --containerWidth: #{1520px - 80px};
        }
    }
}

// **********************************************************
// NEWS & EVENTS & SEARCH
.wrapperNewsEvents {
    padding-top: 3rem;
    --#{$prefix}bgNews: none;

    /* NEWS */
    &__news {
        --#{$prefix}-newsItem-bg: var(--#{$prefix}white);
        background-color: var(--#{$prefix}white); /* TODO: potser s'haurà de treure */  
         
    }

    // EVENTS
    &__events {
        background: url('../img/bg-event-mobile.png') no-repeat center center / cover var(--#{$prefix}primary);   
        .events-slider {
            position: relative;
            z-index: $zindex-flat + 1;
        }     
        &.ui.segment {
            padding-bottom: 4rem;
        }
        // 1600 pixels - 100em
        @include media-breakpoint-up(xxl) {
            &.ui.segment {
                padding-bottom: 9rem;
            }
        }
    }

    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        position: relative;
        background-image: url('../img/bg-announcements.png'), var(--#{$prefix}bgNews);
        background-repeat: no-repeat; 
        background-position: right top, bottom left;
        background-size: auto , contain;
        background-color: var(--#{$prefix}body-bg);
        &::after {
            content: url('../img/bg-news-circle.svg');
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: $zindex-flat;
            display: block;
            width: 377px;
            height: 317px;
        }
        &__news {
            background-color: transparent;
        }
        &__events {
            background: none transparent;
        }
        &__search.ui.basic.segment {
            background: url('../img/bg-news-photo.png') no-repeat top right / auto var(--#{$prefix}light);
        }
    }    
    &:has(#homeEvents){
        --#{$prefix}bgNews: url('../img/bg-news.png');
        @media (min-width: 75em) {
            --#{$prefix}bgNews: url('../img/bg-news-lg.png');            
        }
        
        // 2400 pixels
        @media (min-width:137em)  {
            --#{$prefix}bgNews: url('../img/bg-news-xl.png');        
        }

    } 
}



// **********************************************************
// BANNERS
.bannersHome { 
    font-family: $headings-font-family;
    margin-top: 5rem;
    margin-bottom: 3rem;
    body.frontpage & {
        background-image: linear-gradient(to bottom, var(--#{$prefix}light) 0, var(--#{$prefix}light) 7.5rem, var(--#{$prefix}white) 7.5rem, var(--#{$prefix}white) 100%);
        margin-top: 0;
    }
    .ui.cards {
        justify-content: center;
    }
    .ui.cards .ui.card.bannersHome__item { 
        --#{$prefix}bg-opacity: .50;
        --#{$prefix}text-color: var(--#{$prefix}white);
        text-align: center;
        min-height: 100vw;
        // aspect-ratio: 1 / 1;
        color: var(--#{$prefix}text-color); 
        background-image: linear-gradient(rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity)),rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity))), var(--#{$prefix}bgBanners, url(../img/bg-banners.jpg) );
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto, cover;
        background-attachment: scroll, fixed; 
        transition: $transition-base;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        border-radius: 0;
        box-shadow: none;
        &::after {
            content: none;
        }
        &:last-child{
            & > * {
                position: relative;
                z-index: $zindex-flat;
            }
            &::before {
                content: '';
                display: block;
                // width: 19.5rem;
                // height: 19.5rem;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                background: url(../img/bg-profiles.svg) no-repeat right bottom / contain transparent;
                z-index: $zindex-flat - 1;
            }
        }
        .bannersHome__item {
            &__image {
                width: 8rem;
                height: 8rem;
                background-color: var(--#{$prefix}primary);
                border-radius: 50% !important;
                margin: 0 auto 1rem auto;
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    height: 4rem;
                    width: auto;
                }
            }
            &__title.header {
                margin-bottom: 1rem;
                color: var(--#{$prefix}text-color);
                font-size: calc(#{$h2-font-size} * var(--#{$prefix}fs-scale));
            }
            &__link {
                text-decoration: none;
                color: var(--#{$prefix}text-color);
                &:hover, :focus {
                    text-decoration: underline;
                    color: var(--#{$prefix}text-color);
                }
            }
            &__text.content{
                text-align: center;
                border-top: none;
                flex-grow: 0;
            }
            &__desc.description {
                font-size: calc(0.875rem * var(--#{$prefix}fs-scale));
                color: var(--#{$prefix}text-color);
            }
            &__more {
                text-decoration: underline;
                font-weight: $font-weight-bold;
                font-size: calc(#{$h6-font-size} * var(--#{$prefix}fs-scale));
                color: var(--#{$prefix}text-color);
                &:hover, &:focus-within {
                    --#{$prefix}bg-opacity: .90;
                    --#{$prefix}text-color: var(--#{$prefix}secondary); 
                }
            }
        }
        &:hover, &:focus-within {
            --#{$prefix}bg-opacity: .90;
            --#{$prefix}text-color: var(--#{$prefix}secondary); 
        }
        &-profiles {            
            &:hover, &:focus-within {
                --#{$prefix}text-color: var(--#{$prefix}white);
                --#{$prefix}bg-opacity: .50;
            }
            
            .bannersHome__item-profiles {
                &__link {
                    color: var(--#{$prefix}text-color);
                    transition: $transition-base;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-height: 4rem;
                    background: transparent;
                    /* Note: currently only Safari supports backdrop-filter */
                    -webkit-backdrop-filter: blur(13px);
                    backdrop-filter: blur(13px);
                    background-color: rgba(var(--#{$prefix}white-rgb), 0.15);
                    font-weight: $font-weight-bold;
                    text-decoration: none;
                    padding: .5em .75rem .5em 1rem;
                    position: relative;
                    .bi {
                        transition: $transition-base;
                    }
                    &:hover, &:focus {
                        background-color: rgba(var(--#{$prefix}secondary-rgb), 0.80);
                        color: var(--#{$prefix}primary);
                        text-decoration: none;
                        .bi {
                            transform: translateX(.25rem);
                        }
                    }
                }
            }
        }
    }
    // 320 pixels - 20em
    @include media-breakpoint-up(sm) {
        .ui.cards .ui.card.bannersHome__item { 
            min-height: 25rem;           
            width: 25rem;
        }
    }
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        &__item {
            padding: 1.5rem;
        }
    }
}


// **********************************************************
// ICON LINKS
.iconLinkItem {
    --#{$prefix}iconLinkItemColor: var(--#{$prefix}primary);
    transition: $transition-base;
    text-decoration: none;
    color: var(--#{$prefix}iconLinkItemColor);
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
    align-items: center;
    &__iconWrapper {
        flex-shrink: 0;
        transition: $transition-base;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 6rem;
        height: 6rem;
        border: 2px solid var(--#{$prefix}iconLinkItemColor);
        border-radius: 50%;
    }
    &__icon {
        transition: $transition-base;
        height: 3rem;
        font-size: 3rem;
        line-height: 1;
        width: auto;
        color: var(--#{$prefix}iconLinkItemColor)
    }
    &__text {
        margin-left: 1rem;
        font-size: calc(#{$h5-font-size} * var(--#{$prefix}fs-scale));
        font-family: $headings-font-family;
    } 
    &:hover, &:focus{
        --#{$prefix}iconLinkItemColor:  var(--#{$prefix}secondary);
        --#{$prefix}link-hover-color: var(--#{$prefix}secondary);
        text-decoration: none;
        .iconLinkItem__iconWrapper {
            background-color: var(--#{$prefix}iconLinkItemColor);
        }
        .iconLinkItem__icon {
            color: var(--#{$prefix}white);
        }
    }
}