// **********************************************************
// MENU
body.contenttype-industry {
    &.headerNegativeBottom .ui.basic.segment.content-area {
        padding-top: 0;
        margin-top: 0;
    }
	.ui.basic.segment.content-area {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.instrumentMenu {
		margin-top: -4rem;
		position: relative !important;
		z-index: $zindex-flat; 
		margin-bottom: 4rem;
	}
}


// **********************************************************
// EVENTS
.industryEvents {
	&.ui.segment.basic {
		background: url(../img/rodonetes-related.jpg) no-repeat top right / cover var(--#{$prefix}light);
		background-blend-mode: multiply;
	}
	.slider-wrapper {
		--slide-spacing: 0;
		--slide-size: 100%;
		--slide-height: auto; 
	}
	.slideControls {
		display: flex;
		justify-content: flex-end;
	}
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		.slider-wrapper {
			--slide-spacing: 1.5rem;
			--slide-size: 50%;
			position: relative;
		}
		.slideControls {
			position: absolute;
			right: 0;
			bottom: calc(100% + 1.5rem);
		}

	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		.slider-wrapper {
			--slide-size: 24rem;
		}
	}
}


// **********************************************************
// NEWS
.newsIndustrySectiion {
	.slider-wrapper {
		--slide-size: 100%;
		--slide-spacing: 1.5rem;
		position: relative;
	}
	.slideControls {
		position: absolute;
		bottom: 100%;
		right: 0;
		z-index: $zindex-flat;
	}
	@include media-breakpoint-up(sm) {
		.slider-wrapper {
				--slide-size: 24rem; 
		}
		.slider__viewport {
				max-width: calc(100vw - 1rem); 
		}
		.news-slider, .events-slider{
				margin-right: -1em;
		}
	}
}