button.gdpr-privacy-banner-button.ui.button {
  &, &.primary, &.black {
    background-color: var(--#{$prefix}dark);
    color: var(--#{$prefix}white);
  }
}

.gdpr-privacy-banner .gdpr-privacy-content-wrapper .gdpr-privacy-settings .settings-column .settings-title .ui.toggle.checkbox label {
  padding-right: 5rem;
  display: block;
  line-height: 1.1;
}
#main button.gdpr-privacy-show-banner {
  bottom: auto;
  top: calc( 23rem - 3.5rem );
  padding: 0;
  border-radius: 0;
  background-color: var(--#{$prefix}primary);
  color: var(--#{$prefix}white);
  opacity: 1;
  width: 3.5rem;
  height: 3.5rem;
  z-index: 3;
  border-width: 1px 0 0 0;
  img {
    filter: invert(100%);
    body.highContrast & {
      filter: none;
    }
  }
  
  @media (pointer:coarse) { // with touch screens
    top: 23rem;
  }

  // 992 pixels - 62em
  @include media-breakpoint-up(lg) {
    top: 22.5rem;
  }
  body.is-authenticated & {
    display: none !important;
  }
}

// ***********************************************
// Cookie editor
.gdpr-privacy-banner {
  background-color: var(--#{$prefix}light);
  color: var(--#{$prefix}body-color);
  border-top: 2px solid var(--#{$prefix}primary);
  .ui.toggle.disabled.checkbox label, 
  .ui.toggle.checkbox input:focus:checked ~ label, 
  .ui.toggle.checkbox input:checked ~ label, 
  .ui.toggle.checkbox input:focus ~ label {
    color: var(--#{$prefix}body-color) !important;
  }
}