// **********************************************************
// ICON SELECTOR
// SEARCH
// SITE ADMIN
// ADD NEW VOLTO ITEM 
// DOCUMENT FIRST HEADING
// CARDS
// ICONS
// TOOLBAR 
// GRID IMAGES
// FULL WIDTH CLASS
// HTML BLOCK

// **********************************************************
// ICON SELECTOR
.icon-selector {
	h3.name {
		font-size: $h6-font-size;
	}
}

// **********************************************************
// SEARCH
.block.search .search-details {
	color: rgba(var(--#{$prefix}body-bg-rgb), .8);
	body.highContrast & {
		color: var(--#{$prefix}contrastLight);
	}
}

// **********************************************************
// SITE ADMIN
#field-site_logo-image {
	background-color: var(--#{$prefix}primary);
}

// **********************************************************
// ADD NEW VOLTO ITEM 
div[data-popper-placement] {
	z-index: $zindex-fixed + 1;
}
div.blocks-chooser {
	width: 26rem;
}

// **********************************************************
// DOCUMENT FIRST HEADING
.documentFirstHeading{
	border-bottom: none;
	&::before {
		content: none;
	}

}

// **********************************************************
// CARDS
.ui.cards > .card > .content > .description, 
.ui.card > .content > .description {
	color: rgba(var(--#{$prefix}body-color-rgb), .68);
}

// **********************************************************
// ICONS
.icon.share-nodes {
	background-image: url("data:image/svg+xml,%3Csvg id='share' xmlns='http://www.w3.org/2000/svg' width='19.059' height='20.33' viewBox='0 0 19.059 20.33'%3E%3Cg%3E%3Cpath d='M31.485,13.182a3.56,3.56,0,0,0-2.865,1.459l-5.632-2.884a3.371,3.371,0,0,0-.105-2.333l5.894-3.547a3.554,3.554,0,1,0-.865-2.3,3.541,3.541,0,0,0,.249,1.285L22.249,8.416a3.567,3.567,0,1,0,.225,4.417l5.614,2.875a3.525,3.525,0,0,0-.175,1.049,3.574,3.574,0,1,0,3.574-3.574Z' transform='translate(-16)' fill='%23293E6B'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center center;
	&::before {
		content: none;
	}
	.button:hover > &, .button:focus > & {
		background-image: url("data:image/svg+xml,%3Csvg id='share' xmlns='http://www.w3.org/2000/svg' width='19.059' height='20.33' viewBox='0 0 19.059 20.33'%3E%3Cg%3E%3Cpath d='M31.485,13.182a3.56,3.56,0,0,0-2.865,1.459l-5.632-2.884a3.371,3.371,0,0,0-.105-2.333l5.894-3.547a3.554,3.554,0,1,0-.865-2.3,3.541,3.541,0,0,0,.249,1.285L22.249,8.416a3.567,3.567,0,1,0,.225,4.417l5.614,2.875a3.525,3.525,0,0,0-.175,1.049,3.574,3.574,0,1,0,3.574-3.574Z' transform='translate(-16)' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");

	}
}
.icon.dropdown:not(.socialShare) {
	&::before {
		content: "\f078" !important;
	}
	.ui.dropdown > & {
		font-size: .75em;
		margin-left: .5em;
	}
}

// **********************************************************
// TOOLBAR
#toolbar .toolbar-content {
	z-index: $zindex-fixed + 2;
}


// **********************************************************
// CONTENTS VIEW
.contents-table-wrapper {
	a {
		text-decoration: none;
	}
}

// **********************************************************
// GRID IMAGES
.gridBlock .block.image img {
	object-fit: contain;
	aspect-ratio: unset;
	object-position: unset;
}

// **********************************************************
// FULL WIDTH CLASS
body.has-toolbar .userBlocks .full-width {
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		width: calc(100vw - 5rem) !important;
		margin-left: calc(-50vw + 2.5rem) !important;		
	}
}
.clearfix::after {
	content:'';
	display: block;
	clear: both;
}
.styled-teaser.right,
.styled-teaser.left { float: none !important;}

// **********************************************************
// HTML BLOCK
.html.block iframe {
	border: none;
}