// **********************************************************
// LAYOUT
.slidesWrapper {
	--#{$prefix}slideButtonColor: var(--#{$prefix}primary);
	--#{$prefix}slideButtonBg: var(--#{$prefix}white);
	--#{$prefix}slideButtonColor-hover: var(--#{$prefix}white);
	--#{$prefix}slideButtonBg-hover: var(--#{$prefix}primary);
	
	.slider-wrapper {
		--slide-spacing: 0;
		--slide-size: 100%;
		--slide-height: 19rem; 
		
		position: relative;
	}

	&.ui.segment {
		padding: 0;
		margin: 0;
	}
	.slideControls {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: $zindex-flat;
		display: flex;
		align-items: center;
		& > * + * {
			margin-left: 1.5rem;
		}
	}
}
  

// **********************************************************
// EMBLA
.slider {
	&-wrapper {
		--slide-spacing: 1rem;
		--slide-size: 100%;
		--slide-height: 19rem; 
	}
	&__viewport {
	  overflow: hidden;
	}
	&__container {
	  backface-visibility: hidden;
	  display: flex;
	  touch-action: pan-y;
	  margin-left: calc(var(--slide-spacing) * -1);
	}
	&__slide {
	  flex: 0 0 var(--slide-size);
	  min-width: 0;
	  padding-left: var(--slide-spacing);
	  position: relative;
	}
	&__slide__img {
	  display: block;
	  height: var(--slide-height);
	  width: 100%;
	  object-fit: cover;
	}
	&__slide__number {
	  width: 4.6rem;
	  height: 4.6rem;
	  z-index: 1;
	  position: absolute;
	  top: 0.6rem;
	  right: 0.6rem;
	  border-radius: 50%;
	  background-color: rgba(var(--background-site-rgb-value), 0.85);
	  line-height: 4.6rem;
	  font-weight: 900;
	  text-align: center;
	  pointer-events: none;
	}
	&__slide__number > span {
	  color: var(--brand-primary);
	  background-image: linear-gradient(
		45deg,
		var(--brand-primary),
		var(--brand-secondary)
	  );
	  background-clip: text;
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
	  font-size: 1.6rem;
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	}
  }
  

// **********************************************************
// SLIDES
.slideItem {    
	--#{$prefix}link-color: var(--#{$prefix}white);
	--#{$prefix}slideItem-color: var(--#{$prefix}white);
	--#{$prefix}slideItem-bg: var(--#{$prefix}primary);
	--#{$prefix}slideItem-bg-rgb: #{to-rgb($primary)};
	--#{$prefix}gutter-x: 1.5rem;

    transition: $transition-base;    
    position: relative;    
    color: var(--#{$prefix}slideItem-color);
    font-family: $headings-font-family;
	&::before {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 1;
		display: block;
		background-image: linear-gradient(rgba(var(--#{$prefix}slideItem-bg-rgb), .66), rgba(var(--#{$prefix}slideItem-bg-rgb), .66) );
	}
	&__image {
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
		object-fit: cover;
		z-index: 0;
	}
	&__text {
		height: 45rem;
		position: relative;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 6rem calc(var(--#{$prefix}gutter-x) * 0.5) 9rem calc(var(--#{$prefix}gutter-x) * 0.5);
		text-wrap: balance;
		&--instrument {
			height: 28rem;
			padding-top: 0;
			padding-bottom: 5rem;
			
			.slideItem__title, .slideItem__desc {
				width: 75%;
			}
		}
	}
	&__title {
			margin-bottom: .4em;
			font-size: calc( #{$h1-font-size} * var(--#{$prefix}fs-scale) );
			a {
				text-decoration: none;
			}
	}
	&__link.ui.button.inverted {
			color: var(--#{$prefix}white);
			text-decoration: none;
			&:hover, &:focus {
					color: var(--#{$prefix}secondary);
					text-decoration: none;
					color: var(--#{$prefix}primary);
	 				background-color: var(--#{$prefix}secondary); 
	 				border-color: var(--#{$prefix}secondary);
	 				box-shadow: none !important;
			}
	}
	&__desc {
		font-family: $headings-font-family; 
	}
	&__readmore {
	&.ui.button {
				margin-top: 1.5rem;
	}
	}
	// &:hover, &:focus-within {
	// 		color: var(--#{$prefix}secondary);
	// 		background-image: linear-gradient(rgba(var(--#{$prefix}slideItem-bg-rgb), .88), rgba(var(--#{$prefix}slideItem-bg-rgb), .88) ), var(--#{$prefix}background-image);
	// 		.ui.inverted.button{
	// 				color: var(--#{$prefix}primary);
	// 				background-color: var(--#{$prefix}secondary); 
	// 				border-color: var(--#{$prefix}secondary);
	// 				box-shadow: none !important;
	// 		}
	// }
	
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
			--#{$prefix}gutter-x: 3rem;
			padding-bottom: 4.5rem;
			&__title, &__desc {
					width: 50%;
			}
			&__desc { 
				font-size: calc( 1.375rem * var(--#{$prefix}fs-scale) );
			}
	}
	
	// 1280 pixels - 75em
	@include media-breakpoint-up(xl) {
			--#{$prefix}gutter-x: 4rem;
			height: 47rem;
			&--instrument {
				height: 28rem;
			}
	}


}


// **********************************************************
// DOTS
.slider__dot {
	--#{$prefix}dotColor: var(--#{$prefix}white);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	width: 2.5rem;
	height: 2.5rem;
	cursor: pointer;
	padding: 0;
	&::after {
		content: '';
		display: block;
		height: 0.5rem;
		width: 0.5rem;
		border-radius: 50%;
		border: 2px solid var(--#{$prefix}dotColor);
		background-color: var(--#{$prefix}dotColor);
	}
	&--selected::after {
		background-color: transparent;
	}
	&:hover, &:focus {
		--#{$prefix}dotColor: var(--#{$prefix}secondary);
	}
}


// **********************************************************
// DOTS
.slider__buttons {
	display: flex;
}
.slider__button {
	padding: 0;
	height: 3.5rem;
	width: 3.5rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: var(--#{$prefix}slideButtonBg);
	color: var(--#{$prefix}slideButtonColor);
	border: none;
	cursor: pointer;
	&:hover, &:focus {
		background-color: var(--#{$prefix}slideButtonBg-hover);
		color: var(--#{$prefix}slideButtonColor-hover);
	}
	&__svg {
		fill: currentColor;
		height: 1.5rem;
		width: auto;
	}
	& + & {
		margin-left: .125rem;
	}
	&:disabled {
		opacity: .25;
	}
}