// **********************************************************
// BLOG ITEM
.blogItem {
	--#{$prefix}translate: 0 , 50%;
	&.ui.card {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
		margin: 0;
	}
	&__imgWrapper {
		position: relative;
		margin-bottom: 1rem;
		margin: 0; 
		.blogItem-noImage & {
			border-bottom: 2px solid var(--#{$prefix}primary);
		}
	}
	&__img {
		transition: $transition-base;
		display: block;
		width: 100%;height: auto;
		aspect-ratio: 4 / 3;
		object-fit: cover;
		&__wrapper {
			overflow: hidden;
		}
	}
	.squareMore {
		transition: $transition-base;
		position: absolute;
		right: 1.5rem;
		bottom: 0;
		z-index: $zindex-flat;		
    transform: translate(var(--#{$prefix}translate));
	}
	&__text {
		&.content {
			border-top: 0 !important;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		.blogItem-noImage &.content {
			padding-top: 2rem;
		}
	}
	&__title {
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		font-size: calc(#{$h4-font-size} * var(--#{$prefix}fs-scale)) !important;
		margin-bottom: 1rem;
		margin-right: 5rem;
	}
	&__link {
		color: var(--#{$prefix}primary);
		text-decoration: none;
		&::before {
            content: '';
            display: block;
            position: absolute;
            width: 3.5rem;
            height: 3.5rem;
            top: 0;
            right: 1.5rem;
            transform: translateY(-50%);
            z-index: $zindex-flat + 3;
        }
		&:hover, &:focus {
			text-decoration: underline;
		}
		&__text {
			display: block;
			max-width: 100%;
			overflow: hidden;
		}
	}
	&__desc {
		.card .content &.description {
			font-family: $headings-font-family;
			font-size:  calc(#{$font-size-base} * var(--#{$prefix}fs-scale));
			color: var(--#{$prefix}primary)
		}
	}
	&:hover, &:focus-within {
		--#{$prefix}translate: .5rem , 50%;
        --#{$prefix}squaremore-bg: var(--#{$prefix}primary);
        --#{$prefix}squaremore-color: var(--#{$prefix}white);
		.blogItem__img {
			opacity:.66;
		}
	}
}

// **********************************************************
// PAGE CONTENT
.blogView {
	.content-area.ui.segment.basic  {
		// 768 pixels - 48em
		@include media-breakpoint-up(lg) {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='948.265' height='764.668' viewBox='0 0 948.265 764.668'%3E%3Cdefs%3E%3ClinearGradient x1='0.626' y1='1.099' x2='0.379' y2='0.522' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='1' stop-color='%23f1f1f1'/%3E%3C/linearGradient%3E%3CclipPath %3E%3Cpath d='M15873.693,525.319a770.178,770.178,0,0,1-154.111-15.537,760.668,760.668,0,0,1-143.542-44.558,764.735,764.735,0,0,1-129.9-70.5,770.185,770.185,0,0,1-113.169-93.376,769.69,769.69,0,0,1-93.377-113.174,764.794,764.794,0,0,1-70.507-129.892,761.02,761.02,0,0,1-44.558-143.542A770.213,770.213,0,0,1,15109-239.348h347.586c.021,229.98,187.133,417.082,417.106,417.082a417.724,417.724,0,0,0,95.688-11.076,414.707,414.707,0,0,0,87.884-31.536V503.137c-9.762,2.407-19.674,4.643-29.463,6.646A770.115,770.115,0,0,1,15873.693,525.319Z' transform='translate(-14697.266 487)' fill='url(%23linear-gradient)'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg transform='translate(-411.735 -247.652)' clip-path='url(%23clip-path)'%3E%3Cpath d='M15873.693,525.319a770.178,770.178,0,0,1-154.111-15.537,760.668,760.668,0,0,1-143.542-44.558,764.735,764.735,0,0,1-129.9-70.5,770.185,770.185,0,0,1-113.169-93.376,769.69,769.69,0,0,1-93.377-113.174,764.794,764.794,0,0,1-70.507-129.892,761.02,761.02,0,0,1-44.558-143.542A770.213,770.213,0,0,1,15109-239.348h347.586c.021,229.98,187.133,417.082,417.106,417.082a417.724,417.724,0,0,0,95.688-11.076,414.707,414.707,0,0,0,87.884-31.536V503.137c-9.762,2.407-19.674,4.643-29.463,6.646A770.115,770.115,0,0,1,15873.693,525.319Z' transform='translate(-14697.266 487)' fill='%23f1f1f1'/%3E%3C/g%3E%3C/svg%3E%0A"), url('../img/rodonetes.jpg');
			background-repeat: no-repeat, no-repeat;
			background-position: right top, -18rem calc(100% + 25rem);
			background-size: 67%, auto;
			background-color: var(--#{$prefix}-body-bg);
			margin-top: 0;
			margin-bottom: 0;
			padding-top: calc(1rem + 1em);
			padding-bottom: calc(1rem + 1em);
		}
	}
	&.hasRelatedContent .content-area.ui.segment.basic  {
		padding-bottom: 0 !important;
	}
	&__page {
		display: flex;
		flex-wrap: wrap;
		gap: 3.5rem;
		justify-content: center;
		& > * {
			flex: 0  0 100%;
		}
		
		// 992 pixels - 62em
		@include media-breakpoint-up(lg) {
			 .blogItem {
				flex: 0 1 calc(50% - 1.75rem)
				}
		}
				
		// 1280 pixels - 80em
		@include media-breakpoint-up(xl) {
			.blogItem {
				flex: 1 1 calc(31% - 1.75rem);
				max-width: calc(33.33% - 1.75rem) !important;
			}
		}
	}
}