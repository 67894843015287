// **********************************************************
// RELATED NEWS 
.relatedNews, .relatedEvents {
	--#{$prefix}-newsItem-bg: var(--#{$prefix}light);
	.slider-wrapper {
        --slide-size: 100%;
        --slide-spacing: 1.5rem;
    }
	.slideControls {
		display: flex;
		justify-content: flex-end;
	}
	&.ui.segment.basic {
		margin-top: 0; 
	}
	&__title {
		&.ui.header {
			color: var(--#{$prefix}primary);
		}
	}

	// 576 ixels - 36em
	@include media-breakpoint-up(sm) { 
		.slideControls {
			position: relative;
			.slider__buttons {
				position: absolute;
				right: 0;
				bottom: .5rem;
			}
		}
	}

	@include media-breakpoint-up(sm) {
        .slider-wrapper {
            --slide-size: 24rem; 
        }
	}
	
}
.relatedNews {
	&.ui.segment.basic { 
		background-color: var(--#{$prefix}light);
		margin-top: 5rem;
		.relatedContent + & {
			margin-top: 0;
		}
	}
}

// **********************************************************
// RELATED EVENTS
.relatedEvents {
	.slider__container {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}
	&.ui.segment.basic {
		background-color: var(--#{$prefix}white);
	}
	.eventItem__text {
		aspect-ratio: 3 / 4;
	}
	.eventItem.eventItem-noImage {
		box-shadow: $box-shadow-sm;
	}
}