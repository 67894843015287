body.highContrast {
    $contrastDark: #180059;
    $contrastLight: #FFFFFF;
    
    --#{$prefix}contrastDark:       #{$contrastDark};
    --#{$prefix}contrastLight:      #{$contrastLight};
    --#{$prefix}contrastDark-rgb:   24, 0, 89;
    --#{$prefix}contrastLight-rgb:  255, 255, 255;
    --#{$prefix}body-color-rgb:     255, 255, 255;
    --#{$prefix}primary:            #{$contrastLight};
    --#{$prefix}primary-rgb:        --#{$prefix}contrastLight-rgb; 
    --#{$prefix}tertiary:           #{$contrastLight};   
    --#{$prefix}secondary:          #{$contrastLight};   
    --#{$prefix}black-rgb:          --#{$prefix}contrastLight-rgb;
	--#{$prefix}white:              #{$contrastDark};
	--#{$prefix}white-rgb:          var(--#{$prefix}contrastDark-rgb);
    --#{$prefix}light:              #{$contrastDark};
    --#{$prefix}dark-primary:       #{$contrastDark};
    --#{$prefix}gray-500:           #{$contrastLight};

    --#{$prefix}facebook:           #{$contrastDark};
    --#{$prefix}twitter:            #{$contrastDark};
    --#{$prefix}whatsapp:           #{$contrastDark};
    --#{$prefix}linkedin:           #{$contrastDark};

    --#{$prefix}text-opacity:       1 !important; 

    --#{$prefix}type-bg:            #{$contrastDark} !important;
    --#{$prefix}badge-color:        #{$contrastLight} !important;


    * {
        background-image: none !important;
    }
    @each $color, $value in $theme-colors {
        .bg-#{$color} {
            background-color: var(--#{$prefix}contrastDark) !important;
        }
        .text-bg-#{$color}, .btn-#{$color}, .btn-outline-#{$color} {
            background-color: var(--#{$prefix}contrastDark) !important;
            color:var(--#{$prefix}contrastLight) !important;
            border-color: var(--#{$prefix}contrastLight) !important;
        }
    }
    .bg-white {
        background-color: var(--#{$prefix}contrastDark) !important; 
    }
    .text-bg-white, .btn-white, .btn-outline-white {
        background-color: var(--#{$prefix}contrastDark) !important;
        color:var(--#{$prefix}contrastLight) !important;
        border-color: var(--#{$prefix}contrastLight) !important;
    }
    .border-white {
        --#{$prefix}white-rgb: var(--#{$prefix}contrastLight-rgb);
    }
    .opacity-50 { opacity: 1 !important;}

    // **********************************************************
    // BODY
    --#{$prefix}body-bg: #{$contrastDark};
    --#{$prefix}body-color: #{$contrastLight};

    
    // **********************************************************
    // HEADER
    --#{$prefix}bgMenu: #{$contrastDark};
    .portalHeader{
        --#{$prefix}white: #{$contrastLight};
        --#{$prefix}white-rgb: --#{$prefix}contrastLight-rgb;
        &.affix {
            --#{$prefix}bgHeader: #{$contrastDark};
        }
    }
    .directActions {
        --#{$prefix}btn-color: #{$contrastLight};
        --#{$prefix}link-color: #{$contrastLight};
        --#{$prefix}directActions-color: #{$contrastLight};
        --#{$prefix}directAcions-bg:  #{$contrastDark};
    }
    .mnPrincipal {
        .desktop-menu-nav.ui.menu .item {
            --#{$prefix}link-hover-color:#{$contrastLight};
            --#{$prefix}nav-link-hover-color: #{$contrastLight};
        }
        .mobile-menu .ui {        
            --#{$prefix}nav-link-color:#{$contrastDark};
            --#{$prefix}nav-link-hover-color: #{$contrastDark};
            --#{$prefix}link-hover-color:#{$contrastDark};
            --#{$prefix}bgMenu: #{$contrastLight};
        }
    }
    .hamburger-wrapper .hamburger.is-active {
        --sa-hamburger-color: #{$contrastDark};
    }


    // **********************************************************
    // LINKS
    --#{$prefix}link-color: #{$contrastLight};
    --#{$prefix}link-hover-color: #{$contrastLight};
    .link-primary {
        color: var(--#{$prefix}link-color) !important;
        &:hover, &:focus {
            color: var(--#{$prefix}link-color) !important;
        }
    }

    // **********************************************************
    // BUTTONS
    .ui.button {
        background-color: $contrastDark !important;
        color: $contrastLight !important;
        &:hover, &:focus {
            color: $contrastDark !important;
        }
    }
    .ui.basic.buttons .button, 
    .ui.basic.button {
        color: $contrastLight !important;
        &:hover, &:focus {
            color: $contrastDark !important;
        }
    }
    .ui.primary.button {
        border: 1px solid $contrastLight;
    }

    // **********************************************************
    // HOME
    .slideItem {
        --#{$prefix}slideItem-color: #{$contrastLight};
        --#{$prefix}slideItem-bg:  #{$contrastDark};
        --#{$prefix}slideItem-bg-rgb: var(--#{$prefix}contrastDark-rgb);
    }
    .bannersHome {
        background-image: none;
        &__item {
            background-image: none;
            background-color: var(--#{$prefix}contrastDark);
            &-profiles__link {
                backdrop-filter: none;
                -webkit-backdrop-filter: none;
                background-color: var(--#{$prefix}contrastDark);
                &:focus, &:hover {
                    color: var(--#{$prefix}contrastLight);
                }
            }
            &::before{
                content: none !important;
            }
            &-profiles__link {
                backdrop-filter: none !important;
                border: 1px solid var(--#{$prefix}contrastDark);
                
                background-color: var(--#{$prefix}contrastLight) !important;
            }
        }
    }  

    
    // **********************************************************
    // SLIDESHOW 
    --#{$prefix}slideButtonBg:		transparent;
	--#{$prefix}slideButtonColor:  	var(--#{$prefix}--#{$prefix}contrastDark);
	--#{$prefix}slideButtonBg-hover:transparent;
	--#{$prefix}slideButtonColor-hover:var(--#{$prefix}contrastDark);    

    .slideItem {
        --#{$prefix}white: #{$contrastLight};
	    --#{$prefix}white-rgb: var(--#{$prefix}contrastLight-rgb);
    } 
    .slider__dot {
        --#{$prefix}dotColor: var(--#{$prefix}contrastLight); 
    }

    
    // **********************************************************
    // NEWS 
    .wrapperNewsEvents{
        background-image: none !important;
        &::after {
            content: none;
        }
    }
    .announcements {
        background-color: transparent;
        box-shadow: none;
    }
    .newsItem {
        &__date {
            opacity: 1;
        }
        &__type .ui.label {
            --#{$prefix}type-bg: #{$contrastDark} !important;
            --#{$prefix}badge-color: #{$contrastLight} !important;
        }
    }

    
    // **********************************************************
    // EVENTS
    .events {
        .text-white { 
            color: rgba(var(--#{$prefix}contrastLight-rgb), var(--sa-text-opacity)) !important;
        }
    }
    .eventItem {
        background-color: var(--#{$prefix}contrastDark);
        &__img {
            position: relative;
        }
        &__text{
            aspect-ratio: unset;
        }
        &-hasImage {
            --#{$prefix}event-color: $contrastLight;
            --#{$prefix}primary: #{$contrastDark};
        }
    }
    .wrapperNewsEvents__search {
        --#{$prefix}gray-100: #{$contrastDark};
        --#{$prefix}light-rgb: #{$contrastDark};
        background-image: none;
    }
    .calendarWrapper {
        .rbc-event {
            border: 1px solid $contrastDark;
            a {
                color: $contrastDark;
            }
        }
        .rbc-today {
            background-color: $contrastLight;
        }
        .rbc-date-cell.rbc-current button {
            color: $contrastDark;
        }
    }
    .searchList .DateInput input[type="text"] {
        border: 1px solid $contrastLight;
    }

    // **********************************************************
    // SEARCH
    .modalSearch .modal-dialog {
        background-color: var(--#{$prefix}contrastDark);
        background-image: none;
    }

    // **********************************************************
    // FORMS
    .form-control{
        background-color: var(--#{$prefix}contrastDark);
        &::placeholder {
            color: #{$contrastLight};
        }
        }
        .form-select {
            background-color: var(--#{$prefix}contrastDark);
            color: #{$contrastLight};
        }

    // **********************************************************
    // MODALS
    .btn-close {
        color: var(--#{$prefix}contrastLight);
        opacity: 1; 
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;           
    }

    // **********************************************************
    // INNER HEADER
    .bgArc {
        background-image: none;
        background-color: var(--#{$prefix}contrastDark);
        --#{$prefix}white: $contrastLight;
    }
    .ui.breadcrumbs {
        --#{$prefix}breadcrumbs-color: 255, 255, 255;
    }

    // **********************************************************
    // FOOTER
    .portalFooter {
        --sa-link-color: var(--#{$prefix}contrastLight);
        --sa-link-hover-color: var(--#{$prefix}contrastLight);
		.text-white {
			color: var(--#{$prefix}contrastLight) !important;
		}
    }
    .patros__link img{ 
        filter: invert(1)
    }

    // **********************************************************
    // LIST
    .searchList {
        .field .ui.input input[type="text"], .field .ui.selection.dropdown {
            border: 1px solid $contrastLight;
            color: $contrastLight;
        }
        &.ui.form .field > label {
            color: $contrastLight;
        }
    }

    // **********************************************************
    // PAGE
    .relatedContent {
        --#{$prefix}related-bg-color: #{$contrastDark};
        --#{$prefix}related-text-color: #{$contrastLight};
        --#{$prefix}related-link-color: #{$contrastLight};
        --#{$prefix}related-icon-color: #{$contrastDark};
        --#{$prefix}link-color: #{$contrastLight};
        --#{$prefix}link-hover-color: #{$contrastLight}; 
    }
    .bg-working {
        background-color: var(--#{$prefix}contrastDark);
    }
    .instrumentMenu {
        --#{$prefix}white: $contrastLight;
    }

      
    // **********************************************************
    // LABELS
    .ui.label {
        background-color: $contrastLight;
        color: $contrastDark;
    }

    // **********************************************************
    // VOLTO
    .transBox__title, .transBox__title.ui.header,
    .transBox.ui.segment, .transBox {
        color: var(--#{$prefix}contrastLight);
    }
}