// **********************************************************
// TEASER
// STATISTICS
// LISTING
// ICONS
// ACCORDION
// BANNER
// TIMELINE
// LABELS
// CIRCULAR
// FORMS
// TRANSPARENT BOX
// HERO
// **********************************************************

// **********************************************************
// TEASER
.teaser a {
	text-decoration: none;
	.teaser-item{
		color: var(--#{$prefix}body-color);
		h2{
			color: var(--#{$prefix}primary);
		}
	}
	&:hover, &:focus {
		text-decoration: underline;
		.teaser-item h2{ 
			columns: var(--#{$prefix}dark);
		}		
	}
}
.styled-teaser.has--align--center .teaser-item {
	flex-direction: column;
}

// **********************************************************
// STATISTICS
// Color
.ui.statistic {
	.primary { color: var(--#{$prefix}primary) !important; }
	.secondary { color: var(--#{$prefix}secondary) !important; }
	.tertiary { color: var(--#{$prefix}tertiary) !important; }
	.white { color: var(--#{$prefix}white) !important; }
	padding-left: 1rem;
	padding-right: 1rem;
	.value p {
		line-height: 1;
	}
}
a.ui.statistic {
	text-decoration: none;
}
.ui.statistics {
	&.one {
		margin-bottom: 0;
		justify-content: space-around;
	}
	&::after {
		content: none !important;
	}
	&.one .statistic.ui {
		min-width: 0;
	}
}
  
  .color-fg-primary { color: var(--#{$prefix}primary) !important; }
  .color-fg-secondary { color: var(--#{$prefix}secondary) !important; }
  .color-fg-tertiary { color: var(--#{$prefix}tertiary) !important; }
  .color-fg-white { color: var(--#{$prefix}white)!important; }
  
  .color-bg-primary { background-color: var(--#{$prefix}primary) !important; }
  .color-bg-secondary { background-color: var(--#{$prefix}secondary) !important; }
  .color-bg-tertiary { background-color: var(--#{$prefix}tertiary) !important; }
  .color-bg-grey { background-color: var(--#{$prefix}light) !important; }
  
  .dark-overlay-4 {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #000;
		opacity: 0.4;
		z-index: 0;
  }
  
  .ui.statistic {
	/* Layout */
	// Flexbox
	.flex-row { flex-direction: row !important; }
	.flex-items-start { align-items: flex-start !important; }
	.flex-items-end { align-items: flex-end !important; }
	.flex-items-center { align-items: center !important; }
	
	// Columns
	@for $value from 2 through 6 {
		.has--#{$value}--columns {
		columns: $value;
		}
	};
	
	// Text
	.text-left,
	.has--text--left,
	.has--textAlign--left { text-align: left !important; }
	
	.has--text--center,
	.has--textAlign--center { text-align: center !important;}
	
	.text-right,
	.has--text--right,
	.has--textAlign--right { text-align: right !important; }
	
	.text-justify,
	.has--text--justify,
	.has--textAlign--justify { text-align: justify !important; }
	
	.bold { font-weight: $font-weight-bold !important; }
	
	.has--text--left {
		text-align: left !important;
		--text-align: left;
	}
	
	.has--text--center {
		text-align: center !important;
		--text-align: center;
		--justify-content: space-evenly;
	}
	
	// Image properties utilities
	
	// image object fit
	.has--object-fit--contain {
		object-fit: contain !important;
		--image-object-fit: contain;
	}
	
	.has--object-fit--cover {
		object-fit: cover !important;
		--image-object-fit: cover;
	}
	
	.has--object-fit--fill {
		object-fit: fill !important;
		--image-object-fit: fill;
	}
	
	.has--object-fit--scale-down {
		object-fit: scale-down !important;
		--image-object-fit: scale-down;
	}
	
	// image object position
	.has--object-position--top {
		object-position: top !important;
		--image-object-position: top;
		object-fit: var(--image-object-fit, none);
	}
	
	.has--object-position--bottom {
		object-position: bottom !important;
		--image-object-position: bottom;
		object-fit: var(--image-object-fit, none);
	}
	
	.has--object-position--left {
		object-position: left !important;
		--image-object-position: left;
	}
	
	.has--object-position--right {
		object-position: right !important;
		--image-object-position: right;
	}
	
	.has--object-position--center {
		object-position: center !important;
		--image-object-position: center;
		object-fit: var(--image-object-fit, fill);
	}
	
	
	// background position utilities
	.has--bg--top {
		background-position: top !important;
	}
	
	.has--bg--center {
		background-position: center !important;
	}
	
	.has--bg--bottom {
		background-position: bottom !important;
	}
	
	
	// Text utilities
	.bold { font-weight: $font-weight-bold !important; }
	.underline { text-decoration: underline !important; }
	
	// Quotes
	.quoted-wrapper h2::before,
	.quoted-wrapper h3::before,
	.quoted::before {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.039' height='16' viewBox='0 0 24.039 16'%3E%3Cg transform='translate(-4 -5.995)'%3E%3Cpath d='M13.8,8.441a5.328,5.328,0,1,0-1.614,7.395,10.461,10.461,0,0,1-2.483,3.9,1.333,1.333,0,1,0,1.917,1.854c4.749-4.921,4.132-10.271,2.179-13.141Zm12.783,0a5.328,5.328,0,1,0-1.614,7.395,10.461,10.461,0,0,1-2.483,3.9A1.333,1.333,0,0,0,24.4,21.588c4.749-4.921,4.132-10.271,2.179-13.141Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/svg%3E");
		display: inline-block;
		width: 1.5em;
		height: 1em;
		background-repeat: no-repeat;
		background-size: contain;
		transform: rotate(180deg);
	}
	
	.quoted-wrapper  h2::after,
	.quoted-wrapper  h3::after,
	.quoted::after {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.039' height='16' viewBox='0 0 24.039 16'%3E%3Cg transform='translate(-4 -5.995)'%3E%3Cpath d='M13.8,8.441a5.328,5.328,0,1,0-1.614,7.395,10.461,10.461,0,0,1-2.483,3.9,1.333,1.333,0,1,0,1.917,1.854c4.749-4.921,4.132-10.271,2.179-13.141Zm12.783,0a5.328,5.328,0,1,0-1.614,7.395,10.461,10.461,0,0,1-2.483,3.9A1.333,1.333,0,0,0,24.4,21.588c4.749-4.921,4.132-10.271,2.179-13.141Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/svg%3E");
		display: inline-block;
		width: 1.5em;
		height: 1em;
		background-repeat: no-repeat;
		background-size: contain;
	}
	
	// Borders
	.bordered {
		border-bottom: 1px solid currentColor !important;
		padding-bottom: 2rem !important;
	}
	
	.fw-light {
		font-weight: $font-weight-semibold !important;
	}
	
	.hiddenStructure {
		border: 0 !important;
		clip: rect(0 0 0 0) !important;
		clip-path: inset(50%) !important;
		height: 1px !important;
		margin: -1px !important;
		overflow: hidden !important;
		padding: 0 !important;
		position: absolute !important;
		white-space: nowrap !important;
		width: 1px !important;
	}
	
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		.mobile-sr-only {
			display: none;;
		}
	}
}

// Theme Overrides
$invertedBackground: transparent;
$mobileStatisticWidth: 100%;
$tabletStatisticWidth: 100%;
$statisticContentMinWidth: 10rem;
$horizontalLabelDistance: .75em;

.ui.inverted.statistics,
.ui.inverted.statistic {
  background: $invertedBackground;
}

.styled-statistic_block {
	margin-bottom: 2rem;
	div.color-bg-secondary {
		padding-top: 1.5rem;
	}
}
.ui.one.statistics .statistic {
	padding-top: 1em;
}


/* Full width statistics for mobile */
.ui.statistic {
  width: $mobileStatisticWidth;
  align-items: center;
}

/* Custom statistic variations & text wrap */
.ui.statistics .ui.statistic {
  max-width: min-content;

  .value,
  .label,
  .slate {
    max-width: fit-content;
  }
  .value {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
  }
}

.ui.statistics:not(.inverted) .ui.statistic {
  .value,
  .label,
  .slate {
    min-width: $statisticContentMinWidth;

    &.primary {
      color: var(--#{$prefix}primary);
    }

    &.secondary {
      color: var(--#{$prefix}secondary);
    }

    &.tertiary {
      color: var(--#{$prefix}tertiary);
    }
  }
}

.ui.statistics.inverted .slate,
.ui.statistics.inverted > a {
  color: var(--#{$prefix}white);
}

.ui.horizontal.statistics .statistic > .slate,
.ui.horizontal.statistic > .slate {
  margin: 0em 0em 0em $horizontalLabelDistance;
}

// 768 pixels - 48em
@include media-breakpoint-up(md) {
	.ui.statistic {
	  width: $tabletStatisticWidth;
	}	
}

/* No horizontal statistic on mobile screens */
@media only screen and (max-width: 767px) {
  .ui.statistics.horizontal .ui.statistic {
    flex-direction: column;
  }
}

// **********************************************************
// LISTING
.block.listing, .block.search {
	--#{$prefix}translate: 0, 50%;
	.listing-item {
		position: relative;
		background-color: var(--#{$prefix}light);
		padding: 1.5rem;
		margin-bottom: 3rem;
		a {
			text-decoration: none;
			&:hover, &:focus {
				text-decoration: underline;
			}
			p {
				color: var(--#{$prefix}body-color);
			}
		}
		&::after {
			content:'';
			transition: $transition-base;
			position: absolute;
			bottom: 0;
			right: 1.5rem;
			display: block;
			transform: translate(var(--#{$prefix}translate));
			z-index: $zindex-flat;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23293E6B' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'%3E%3C/path%3E%3C/svg%3E");
			background-size: 1rem;
			background-position: center center;
			background-color: var(--#{$prefix}squaremore-bg, var(--#{$prefix}white));
			background-repeat: no-repeat;
			height: 3.5rem;
			width: 3.5rem;			
			color: var(--#{$prefix}squaremore-color, var(--#{$prefix}primary) );
			box-shadow: $box-shadow;
		}
		&:hover, &:focus-within {
			--#{$prefix}translate: .5rem , 50%;
			--#{$prefix}squaremore-bg: var(--#{$prefix}secondary);
			a {
				text-decoration: underline;
			}
		}
	}

}
 

// **********************************************************
// ICONS
.iconItem {
	.iconLinkItem__readmore {
		text-decoration: underline;
		font-family: $font-family-sans-serif;
		text-underline-offset: 2px;
	}
	&.variant-icon-01 {
		margin-bottom: 1.5rem;
		display: block;
		.iconLinkItem {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: auto auto auto;
			align-items: start;
			gap: .5rem 1.5rem;
			&__iconWrapper {
				grid-column: 1 / 2;
				grid-row: 1 / 4;
			}
			&__title {
				grid-row: 1 / 2;
				grid-column: 2 / 3;
				&--nodesc {
					grid-row: 2 /3;
				}
			}
			&__desc {
				grid-row: 2 / 3;
				grid-column: 2 / 3;
			}
			&__readmore {
				grid-row: 3 / 4;
				grid-column: 2 / 3;				
			}
			.iconLinkItem__text {
				margin-left: 0;
			}
			&--noDesc {
				display: flex;
				align-items: center;
			}
			&:hover, &:focus{
        --#{$prefix}iconLinkItemColor:  var(--#{$prefix}primary);
        --#{$prefix}link-hover-color: var(--#{$prefix}primary);
				.iconLinkItem__iconWrapper {
						background-color: transparent;
				}
				.iconLinkItem__icon {
					color: var(--#{$prefix}primary);
				}
			}
			&[href]:hover, &[href]:focus{
				--#{$prefix}iconLinkItemColor:  var(--#{$prefix}secondary);
        --#{$prefix}link-hover-color: var(--#{$prefix}secondary);
        text-decoration: none;
        .iconLinkItem__iconWrapper {
            background-color: var(--#{$prefix}iconLinkItemColor);
        }
        .iconLinkItem__icon {
            color: var(--#{$prefix}white);
        }
    	}
		}
		
	}
	&.variant-icon-02 {
		.iconLinkItem {
			flex-direction: column;
			align-items: flex-start;
			&__iconWrapper {
				margin-bottom: 1rem;
			}
			&__title {
				margin-bottom: .75rem;
			}
			&__desc {
				margin-bottom: 0.5rem;
			}
			&:hover, &:focus{
        --#{$prefix}iconLinkItemColor:  var(--#{$prefix}primary);
        --#{$prefix}link-hover-color: var(--#{$prefix}primary);
				.iconLinkItem__iconWrapper {
						background-color: transparent;
				}
				.iconLinkItem__icon {
					color: var(--#{$prefix}primary);
				}
			}
			&[href]:hover, &[href]:focus{
				--#{$prefix}iconLinkItemColor:  var(--#{$prefix}secondary);
        --#{$prefix}link-hover-color: var(--#{$prefix}secondary);
        text-decoration: none;
        .iconLinkItem__iconWrapper {
            background-color: var(--#{$prefix}iconLinkItemColor);
        }
        .iconLinkItem__icon {
            color: var(--#{$prefix}white);
        }
				
    	}
		}
		.iconLinkItem__text {
			margin-left: 0;
		}
	}
}

// ICON V03
.vi03Item {
	margin: 0 0 1.5rem 0;
	display: flex;
	color: var(--#{$prefix}primary);
	max-width: 20rem;
	&__iconWrapper {
		background-color: var(--#{$prefix}light);
		font-size: $font-size-base;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 4em;
		width: 4em;
		flex: 0 0 auto;
		color: var(--#{$prefix}primary);
		margin-right: 1rem;
	}
	&__text {
		padding-top: 0.25em;
		align-items: flex-start;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
				line-height: $headings-line-height;
		font-size: calc(var(--#{$prefix}body-font-size) * var(--#{$prefix}fs-scale));
	}
	&__title {
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		display: block;
		margin-bottom: 0.25em;
	}
	&__desc {
		font-size: calc(var(--#{$prefix}body-font-size) * var(--#{$prefix}fs-scale) * 0.875);
		display: block;
		margin-bottom: 0.5em;
	}
	&__readmore { 
		font-size:.66em;
		text-transform: uppercase;
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		padding: .25em .5em;
		display: inline-block;
	}
	&--link {
		color: var(--#{$prefix}primary);
		text-decoration: none;
		&:hover, &:focus {
			text-decoration: none;
			color: var(--#{$prefix}primary);
			background-color: var(--#{$prefix}light);
			.vi03Item__iconWrapper {
				background-color: var(--#{$prefix}primary);
				color: var(--#{$prefix}white);
			}
			.vi03Item__readmore {
				background-color: var(--#{$prefix}secondary);
				color: var(--#{$prefix}primary);
			}
		}
	}
}
 

// **********************************************************
// ACCORDION
.accordion-block {
	.ui.styled.accordion {
		margin-bottom: 1rem;
		background-color: transparent;
		.title {
			font-family: $font-family-sans-serif;
			font-size: calc(#{$h5-font-size} * var(--#{$prefix}fs-scale));
			font-weight: $font-weight-bold;
			padding: 1rem 1rem 1.1rem 1rem;
			background-color: var(--#{$prefix}light);
			color: var(--#{$prefix}primary);
			.ui.input input {
				color: var(--#{$prefix}primary);
				font-weight: $font-weight-bold;

			}
			.icon {
				transform: rotate(-90deg);			
				margin-left: 1rem;
				flex-shrink: 0;
				height: 2rem !important;
			}
			&.active {
				background-color: var(--#{$prefix}primary);
				color: var(--#{$prefix}white);
				.icon {
					transform: rotate(180deg);	
				}
			}
			&:hover, &:active {
				background-color: var(--#{$prefix}secondary);
				color: var(--#{$prefix}primary);
				body.highContrast & {
					color: var(--#{$prefix}contrastDark);
				}
			}
			
		}
	}
}
.userBlocks > .accordion-block:first-child { margin-top: 3rem;}

// **********************************************************
// BANNER
.bannersHome.ui.basic.segment {
	background-image: none;
	.ui.cards {
		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			.ui.card.bannersHome__item {
				aspect-ratio: auto;
				width: auto;
			}
		}
		// 1280 pixels - 80em
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(3, 1fr);
		}
		
	}
}
 

// **********************************************************
// TIMELINE
.eea.timeline {
	.card.ui {
		box-shadow: none;
		border-radius: 0;
		background-color: var(--sa-light);		
		.header {
			color: var(--sa-primary);
			font-family: $font-family-sans-serif; 
			font-weight: $font-weight-bold;
			font-size: calc(1.25em * var(--#{$prefix}fs-scale));
		}
		.ui[class*="top attached"].label, 
		.ui.attached.label {
			border-radius: 0;
			min-height: 2rem;
		}
		.description {
			color: rgb(var(--#{$prefix}body-color-rgb), 1);
		}
	}
	.column.two {
		flex-basis: 0;
		flex-shrink: 1;
	}
	.column.ten {
		flex-grow: 1;
	}
}
 

// **********************************************************
// LABELS
.card {
	.ui.label, .ui.labels {
		&.blue {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}primary) !important;
			border-color: var(--#{$prefix}primary) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.red {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}red) !important;
			border-color: var(--#{$prefix}red) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.orange {
			color: var(--#{$prefix}dark) !important;
			background-color: var(--#{$prefix}orange) !important;
			border-color: var(--#{$prefix}orange) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.yellow {
			color: var(--#{$prefix}dark) !important;
			background-color: var(--#{$prefix}yellow) !important;
			border-color: var(--#{$prefix}yellow) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.olive {
			color: var(--#{$prefix}dark) !important;
			background-color: var(--#{$prefix}olive) !important;
			border-color: var(--#{$prefix}olive) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.green {
			color: var(--#{$prefix}dark) !important;
			background-color: var(--#{$prefix}green) !important;
			border-color: var(--#{$prefix}green) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.teal {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}teal) !important;
			border-color: var(--#{$prefix}teal) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.violet {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}violet) !important;
			border-color: var(--#{$prefix}violet) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.purple {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}purple) !important;
			border-color: var(--#{$prefix}purple) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.pink {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}pink) !important;
			border-color: var(--#{$prefix}pink) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.brown {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}brown) !important;
			border-color: var(--#{$prefix}brown) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.grey {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}grey) !important;
			border-color: var(--#{$prefix}grey) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
		&.black {
			color: var(--#{$prefix}white) !important;
			background-color: var(--#{$prefix}black) !important;
			border-color: var(--#{$prefix}black) !important;
			body.highContrast & {
				background-color: var(--#{$prefix}contrastLight) !important;
				color: var(--#{$prefix}contrastDark) !important; 
				border-color: var(--#{$prefix}contrastLight) !important;
			}
		}
	}
}
 

// **********************************************************
// CIRCULAR
.eea.timeline i.inverted.bordered.icon, 
.eea.timeline i.inverted.circular.icon {
	&.blue {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}primary) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.red {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}red) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.orange {
		color: var(--#{$prefix}dark) !important;
		background-color: var(--#{$prefix}orange) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.yellow {
		color: var(--#{$prefix}dark) !important;
		background-color: var(--#{$prefix}yellow) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.olive {
		color: var(--#{$prefix}dark) !important;
		background-color: var(--#{$prefix}olive) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.green {
		color: var(--#{$prefix}dark) !important;
		background-color: var(--#{$prefix}green) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.teal {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}teal) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.violet {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}violet) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.purple {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}purple) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.pink {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}pink) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.brown {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}brown) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.grey {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}grey) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
	&.black {
		color: var(--#{$prefix}white) !important;
		background-color: var(--#{$prefix}black) !important;
		body.highContrast & {
			background-color: var(--#{$prefix}contrastLight) !important;
			color: var(--#{$prefix}contrastDark) !important; 
		}
	}
}

// **********************************************************
// FORMS
.block.form {
	h2 {
		margin-bottom: 1.5em;
	}
	.ui.segment {
		background-color: var(--#{$prefix}light);
		box-shadow: none; 
		border: none;
		max-width: 52rem;
		margin-left: auto !important;
		margin-right: auto !important;
		&.padded {
			padding-left: .5em;
			padding-right: .5em;
			// 992 pixels - 62em
			@include media-breakpoint-up(lg) {
				padding-left: 1.5em;
				padding-right: 1.5em;
			}
		}
	}
	.ui.grid {
		flex-direction: column;
		.row > .column {
			width: 100% !important;
		}
	}
	.inline.field {
		margin-bottom: 2.5rem;
		.wrapper {
			border-bottom: none;
			min-height: 0;
			label {
				display: block;
				margin-bottom: 1rem !important;
			}
		}
	}
	
	.ui.form {
		.field .wrapper > label,
		.ui.checkbox label{
			color: var(--#{$prefix}body-color);
			body.highContrast & {
				color: var(--#{$prefix}contrastLight);
			}
		}
		input {
			&:not([type]),
			&[type="date"],
			&[type="datetime-local"],
			&[type="email"],
			&[type="number"], 
			&[type="password"], 
			&[type="search"], 
			&[type="tel"], 
			&[type="time"], 
			&[type="text"], 
			&[type="file"], 
			&[type="url"] {
				border: 1px solid var(--#{$prefix}gray-500);
				padding-left: 1rem;
				padding-right: 1rem;
				&:focus {
					color: var(--#{$prefix}body-color);
					border-color: var(--#{$prefix}primary);
				}
			}
		}
		textarea, select, .react-select__control {
			border: 1px solid var(--#{$prefix}gray-500);
			padding-left: 1rem;
			padding-right: 1rem;
				&:focus {
					color: var(--#{$prefix}body-color);
					border-color: var(--#{$prefix}primary);
				}
		}
		.inline.field.textarea textarea {
			padding: 1rem;
			min-height: 6rem;
			margin-top: 0;
		}
		.dropzone-placeholder {
			margin-top: 0;
		}
		.label-file-widget-input {
			font-size: var(--#{$prefix}btn-font-size);
			font-family: $headings-font-family;
			font-weight: $font-weight-bold;
			border-radius: 1.5rem;
			color: var(--#{$prefix}primary);
			border-color: currentColor;
			&:hover, &:focus {
				color: var(--#{$prefix}secondary);
			}
		}
		.field .static-text {
			margin-left: 1rem;
			margin-right: 1rem;
		}
		p.help {
			color: var(--#{$prefix}body-color);
		}
	}
	.ui.checkbox label::before {
		box-shadow: none;
		border-color: var(--#{$prefix}dark-primary);
		border-radius: .125rem;
	}
	.checkbox-group, .radio-group {
		padding-top: 0;
		padding-bottom: 0;
	}
	.DateInput {
		width: 20rem;
		max-width: 100%;
		.DateInput_input  {
			width: 100% !important;
		}
	}
	.field-file-name {
		padding-bottom: 0;
		&:empty {
			padding-top: 0;
		}
	}
}

// **********************************************************
// TRANSPARENT BOX
.transBox {
	&__wrapper {  
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		& > * {
			flex: 1 1 0;
		}
		// 992 pixels - 62em
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
	}
	&.ui.segment, & {
		min-height: 25rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-items: center;
		justify-content: center;
		background-image: linear-gradient(to right, $dark-primary 0%, $primary 100%);
		color: var(--#{$prefix}white);
		overflow: hidden;
		position: relative !important;
		margin-bottom: 0;
		margin-top: 0;
		width: 100%;
		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			min-width: 25rem;
			width: auto;
		}
	}
	&::before {
		content:url('../img/bg-news-circle.svg');
		display: block;
		position: absolute;
		width: 377px;
    height: 317px;
		z-index: $zindex-flat + 1;
	}
	&--noImage::before {
		transform: rotate(180deg); 
		top: 0;
		left: 0;
	}
	&--hasImage{
		&::before {
			bottom: 0;
			right: 0;
		}
	}
	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		mix-blend-mode: luminosity;
		opacity: .33;
		object-fit: cover;
		object-position: center center;
		z-index: $zindex-flat;
	}
	&__title {
		&, &.ui.header {
			font-size: calc(#{$h2-font-size} * var(--#{$prefix}fs-scale));
			text-transform: uppercase;
			color: var(--#{$prefix}white);
			margin-bottom: 1rem;
			text-align: center;
			position: relative;
			z-index: $zindex-flat + 2;
		}
	}
	&__desc {
		text-align: center;
		font-size: calc(#{$h5-font-size} * var(--#{$prefix}fs-scale));
		font-family: $headings-font-family;
		margin-bottom: 1.5rem;
		position: relative;
		z-index: $zindex-flat + 2;
	}
	&__link {
		position: relative;
		z-index: $zindex-flat + 2;
	}
}

// **********************************************************
// HERO
.block.hero {
	.hero-text h1 {
		font-size: calc(1.25rem * var(--#{$prefix}fs-scale));
	}
	.hero-image {
		object-fit: cover;
	}
	.hero-body {
		padding: 1rem;
	}
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		.hero-text h1 {
			font-size: calc(2.5rem * var(--#{$prefix}fs-scale));
		}
		.ui.column.grid > .six.wide.tablet.column > & {
			.hero-text h1 {
				font-size: calc(1.25rem * var(--#{$prefix}fs-scale));
			}
		}
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		.hero-body {
			padding: 1.5rem;
		}
	}
	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		.ui.column.grid > .six.wide.tablet.column > & {
			.hero-text h1 {
				font-size: calc(1.5rem * var(--#{$prefix}fs-scale));
			}
		}
	}
	// 1600 pixels - 100em
	@include media-breakpoint-up(xxl) {
		.ui.column.grid > .six.wide.tablet.column > & {
			.hero-text h1 {
				font-size: calc(2rem * var(--#{$prefix}fs-scale));
			}
		}
	}
}