// **********************************************************
// MODAL SEARCH
.modalSearch {
	.ui.form .searchbox {
		&.field {
			padding-left: 0;
			border-left: 0; 
		}
		&__label {
			flex: 0 0 100%;
			margin-bottom: 0.25rem;
		}		
		&.field .ui.input input{
			width: auto;
			flex: 1 1 auto;
			border-bottom: 1px solid var(--#{$prefix}light) !important;
		}
		&.field .searchbox__button {
			font-family: $font-family-body;
			font-weight: $font-weight-bold;
			font-size: $font-size-sm;
			background-color: var(--#{$prefix}primary);
			color: var(--#{$prefix}white);
			display: inline-flex;
			align-items: center;
			height: 3rem;
			padding: .25rem 1rem;
			border-radius: 1.5rem;
			margin-left: 1rem;
			&__icon-glass {
				display: none;
			}
			&__icon-arrow {
				display: inline-block !important;
				margin-left: 2rem;
				opacity: .66;
				font-size: 1.125rem;
				// 992 pixels - 62em
				@include media-breakpoint-up(lg) {
					margin-left: 4rem;	
				}
			}
			&:hover, &:focus {
				background-color: var(--#{$prefix}secondary);
				color: var(--#{$prefix}primary);
			}
		}
		&__button__text.visually-hidden {
			position: static !important;
			width: auto !important;
			height: auto !important;
			margin: 0 !important;
			clip:  none !important;
		}
	}

}


// **********************************************************
// SEARCH HOME
.searchHome {
	color: var(--#{$prefix}primary);
	font-family: $headings-font-family;
	&.ui.segment.basic {
		background-color: var(--#{$prefix}light);
		padding: 3rem 0;
		margin-top: 0;
		margin-bottom: 0;
	}
	&__title {
		&.ui.header {
			margin-bottom: 1.5rem;
			color: var(--#{$prefix}primary);
		}
	}
	&__form {
		display: flex;
		align-items: flex-end;
	}
	&__field {
		flex-grow: 1;
		&.field {
			margin-bottom: 0 !important;
		}
	}
	&__input {
		.ui.form & input[type="search"] {
			background-color: transparent;
			border-bottom-color: var(--#{$prefix}primary);
		}
	}
	&__button {
		&.ui.button {
			margin-left: 1rem;
		}
	}
}


// **********************************************************
// SEARCH RESULTS
body.searchlisting .ui.basic.segment.content-area {
	padding-top: 0;
	margin-top: 0;
}
.searchList-search.ui.form {
	max-width: 47rem;
	margin: -5rem auto 3rem auto;
	
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		margin-top: 0;
	}
	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		padding-left: 4rem;
		padding-right: 4rem;		
	}
}
.searchListFacets {
	background-color: var(--#{$prefix}primary);
	color: var(--#{$prefix}white);
	padding: 1.5rem 2rem;
	font-family: $headings-font-family;
	.ui.header {
		color: var(--#{$prefix}white);
	}
	.field {
		margin-bottom: 1rem;
	}
	.ui.checkbox label {
		color: var(--#{$prefix}white);
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		&.ui.form{
			position: sticky !important;
			top: 4.5rem;
		}
	}
}
.section-search .items_total.sectionSort .button-sort {
	margin-left: 0.5rem;
	&:hover, &:focus {
		color: var(--#{$prefix}primary);
	}
}


// **********************************************************
// SEARCH RESULTS ITEM
.results {
	--#{$prefix}bg-search-result: var(--#{$prefix}light);
}

.searchResultItem {
    --#{$prefix}translate: 0 , 50%;
	&.ui.card {
		background-color: var(--#{$prefix}bg-search-result, var(--#{$prefix}light) );
		position: relative;
		padding: 1.5rem;
		box-shadow: none;
		border-radius: 0;
		.content {
			border-top: none !important;
			padding: 0;
		}
	}
	&__title {
		font-size: $h5-font-size;
		font-weight: $font-weight-bold;
		&.header {
			margin-bottom: .25rem;	
		}
	}
    &__link {
        text-decoration: none;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 3.5rem;
            height: 3.5rem;
            bottom: 0;
            right: 1.5rem;
            transform: translateY(50%);
            z-index: $zindex-flat + 3;
        }
    }
	&__desc {
		font-size: $h5-font-size;
		font-family: $headings-font-family;
		color: var(--#{$prefix}primary);
	}
    &__path {
		font-family: $headings-font-family;
		font-size: $h6-font-size;
		margin-bottom: 1rem !important;
		color: var(--#{$prefix}primary);
	}
    .squareMore {
        transition: $transition-base;
        position: absolute;
        bottom: 0;
        right: 1.5rem;
        transform: translate(var(--#{$prefix}translate));
        z-index: $zindex-flat + 2;
    }
    &:hover, &:focus-within{
        --#{$prefix}translate: .5rem , 50%;
        --#{$prefix}squaremore-bg: var(--#{$prefix}primary);
        --#{$prefix}squaremore-color: var(--#{$prefix}secondary);
    }
}


// **********************************************************
// SEARCH RESULTS TOTAL AND SORT
.section-search .sectionSort {
	padding-top: 2rem;
	flex-wrap: wrap;
	&__total {
		font-weight: $font-weight-bold;
		color: var(--#{$prefix}primary);
		text-transform: uppercase;
		font-size: $font-size-sm;
		white-space: nowrap;
		margin-bottom: 1.5rem;
	}

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		padding-top: 0;
		margin-bottom: 0;
	}
}