// **********************************************************
// LAYOUT
.contactProfile {
  &.segment {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 4rem;
  }
  &__title {
    &, &.ui.header {
      color: var(--#{$prefix}primary);
      margin-bottom: 1rem;
      font-size: calc(#{$h2-font-size} * var(--#{$prefix}fs-scale));
    }
  }
  &__email {
    font-size: calc(#{$h3-font-size} * var(--#{$prefix}fs-scale));
    font-family: $headings-font-family;
    color: var(--#{$prefix}grey);
    text-decoration: underline;
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;
  }
  &__desc {
    margin-bottom: 1em;
  }
}

// **********************************************************
// ITEM
.contactProfileItem {
  &, &.ui.card {
    box-shadow: none;
    border-radius: 0;
    background-color: var(--#{$prefix}light);
    flex-direction: row;
    align-items: flex-start;
    border:none;
  }
  &__figure {
    padding: 0;
    margin: 0; 

    flex: 0 0 33.33%;
    position: relative;
    &__image {
      display: block;
      height: auto;
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center center;
    }
  }
  &__text {
    &, .ui.card > &.content {
      flex: 1 1 auto;
      border-top: none !important;
      font-family: $headings-font-family;
      border-radius: 0 !important;
    }
  }
  &__title {
    &, .ui.card > .content > &.header{
      color: var(--#{$prefix}primary);
      font-size: calc(#{$h5-font-size} * var(--#{$prefix}fs-scale));;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
    &--noDesc {
      margin-bottom: 0.5rem !important;
    }
  }
  &__desc {
    &, &.meta {
      color: var(--#{$prefix}primary) !important;
      font-size: calc(#{$font-size-sm} * var(--#{$prefix}fs-scale));
      margin-bottom: 0.5rem;
    }
  }
  &__phone, &__email {
    display: block;
    text-decoration: none;
    font-size: calc(#{$font-size-sm} * var(--#{$prefix}fs-scale));
    color: var(--#{$prefix}body-color);
    font-weight: $font-weight-bold;
  }

  // Variation
  &--imageTop {
    &, &.ui.card {
      flex-direction: column;
    }
    .contactProfileItem__figure {
      flex-basis: autos;
    }
  }
}