.customer-satisfaction {
	&.ui.segment, & {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	.ui.grid > .row {
		max-width: 60rem;
		margin-left: auto;
		margin-right: auto;
	}
	&__title {
		color: var(--#{$prefix}primary);
		margin-bottom: 0;
	}
	&__desc {
		font-size: calc(#{$font-size-base} * var(--#{$prefix}fs-scale));
		font-family: $headings-font-family;
		color: var(--#{$prefix}primary);
		margin-bottom: 2rem !important;
	}
	.buttons {
		flex-wrap: wrap;		
	}
	& .buttons &__btn.ui.button {
		min-height: 3rem;
		min-width: 13rem;
		max-width: 100%;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
	}
	.content.customer-satisfaction__btn__icon {
		margin-left: 1rem;
		&-no {
			translate: 0 .25em;
		}
	}
	.ui.button {
		transition: $transition-base;
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		font-size: calc(#{$font-size-sm} * var(--#{$prefix}fs-scale));
		font-family: $headings-font-family;
		margin: .5rem 1.25rem;
		&:hover, &:focus {
			color: var(--#{$prefix}primary);
			background-color: var(--#{$prefix}secondary);
			.visible.content {
				opacity: 1 !important;
			}
		}
	}
	.ui.form textarea::placeholder {
		opacity: .8;
		color: var(--#{$prefix}body-color);
	}

	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		.buttons {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}
		&__desc {
			margin-bottom: 0 !important;
		}
		.ui.button {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
}