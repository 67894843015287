// BIG FONT
body.fontBig {
  // 1280 pixels - 80em
  @include media-breakpoint-up(xl) {
    .headerLogo__wrapper {
      order: 1
    }
    .mnPrincipal__wrapper {
      flex: 0 0 100%;
      width: 100%;
      order: 3;
    }
    .headerLang__wrapper {
      order: 2;
      margin-left: auto;
    }
  }
}