// **********************************************************
// ANNOUNCEMENTS
.announcements {
    
    .slider-wrapper {
        --slide-spacing: 0;
        --slide-size: 100%;
        --slide-height: auto; 
    }

    &.ui.segment {
        box-shadow: $box-shadow-sm;
        border-radius: 2.5rem;
        background-color: rgba(var(--#{$prefix}white-rgb), .9);
        text-align: center;
        padding: 1.5rem;
        position: relative !important;
    }
    &__title {
        color: var(--#{$prefix}primary);
        margin-top: 0;
    }
    &__item__link {
        transition: $transition-base;
        text-decoration: none;
        color: var(--#{$prefix}primary);
        &:hover, &:focus {
            color: var(--#{$prefix}secondary);
            text-decoration: underline;
        }
    }
    .slideControls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-top: 1rem;
        .slider__buttons {
            display: flex;
            justify-content: space-between;
        }
    }

    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        display: flex;
        &__title {
            text-align: left;
            margin-right: 1.5rem;
            margin-bottom: 0;
            flex: 0 0 auto;
        }
        &-slider {
            flex: 1 1 auto;
            position: relative;
        }
        .slider__viewport {
            margin-left: 3rem;
            margin-right: 3rem;
        }
        .slideControls {
            position: static;
            margin-top: 0;
            left: auto;
            top: auto;
            .slider__buttons {
                display: block;
            }
            .slider__button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: $zindex-flat;
                width: 2.5rem;
                height: 2.5rem;
                &--prev {
                    left: 0;
                }
                &--next {
                    right: 0;
                }
            }
        }
        .slider__container {
            align-items: center;
            .slider__slide {
                display: flex;
                flex-wrap: wrap;            
                justify-content: center;align-items: center;
                min-height: 2.5rem;
            }
        }
    }

    // 1280 pixels - 80em
    @include media-breakpoint-up(xl) {
        &.ui.segment {
            padding-left: 2rem;
        }
        &__title {
            margin-right: 5rem;
        }
    }
}