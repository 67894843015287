// **********************************************************
// SEARCH
.searchList {
    border-radius: var(--#{$prefix}border-radius-2xl);
    margin-top: -5rem;
    position: relative;
    z-index: $zindex-flat + 3;
	color: var(--#{$prefix}primary);
	background-color: var(--#{$prefix}white);
	padding: 1.5rem;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	&.ui.form {
		position: relative !important;
		z-index: $zindex-flat + 3;
	}
    &__btn button {
        min-width: 10rem; 
    }
    &__send {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        z-index: $zindex-flat + 4;
    }
	.field {
		.ui.input input[type="text"], .ui.selection.dropdown {
			font-size: 1.125rem;
			font-family: $headings-font-family;
			background-color: var(--#{$prefix}light);
			border-bottom-color: transparent ; 
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			.default.text {
				color: rgba(var(--#{$prefix}primary-rgb), .75);
			}
			&::placeholder {
				color: rgba(var(--#{$prefix}primary-rgb),1);
			}
			&:focus {
				border-bottom-color: var(--#{$prefix}secondary);
			}
		}
	} 
    .ui.selection.dropdown {
        color: var(--#{$prefix}primary);
    }
    .ui.selection.dropdown:not(.multiple):not(.search) > .dropdown.icon {
        font-size: 1em;
    }
    &__result {
        max-width: 60rem;
        margin: 0 auto 1.5rem auto !important;
        text-align: right;
    }

    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        transform: translateY(-50%);
        &__pseudoborder {
            position: relative;
            padding-left: 1rem;
            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 3rem;
                border-left: 1px solid var(--#{$prefix}gray-400);
            }
        }
    }
    // 1280 pixels - 75em
    @include media-breakpoint-up(xl) {
        border-radius: 100rem;
        padding: 1rem 3rem 1.5rem 3rem;
    }
}
.ui.form .ui.dropdown:not(.multiple):not(.search) .menu .selected.item::after,
.ui.form .ui.dropdown .menu .item:hover::after {
    content: '\f00c' !important; 
    font-size: 0.8em;
}

body.newslisting .ui.basic.segment.content-area {
	// 992 pixels - 62em
    @include media-breakpoint-up(lg) {
		padding-top: 0;
		margin-top: 0;
	}
}


// **********************************************************
// PAGINATION
.pagination {
    margin-top: 3rem;
    .ui.menu {
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
        font-size: 1.125rem;
        &.secondary.attached {
            border: none;
            justify-content: center;
        }
        &::after {
            content: none;
        }
        .item {
            font-weight: $font-weight-bold;
            &.active { 
                color: var(--#{$prefix}primary);
                font-weight: $font-weight-bold;
            }
        }
    }
}