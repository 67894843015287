.ui.breadcrumbs {
	--#{$prefix}breadcrumbs-color: var(--#{$prefix}white-rgb);
	font-size: var(--#{$prefix}body-font-base);
	color: rgb(var(--#{$prefix}breadcrumbs-color));
	flex-wrap: wrap;
	line-height: $line-height-base;
	padding-left: 0;
	padding-right: 0;
	position: relative;
	z-index: $zindex-flat + 1;
	&__separator {
		margin: 0 .5em;
	}
	a {
		color: rgb(var(--#{$prefix}breadcrumbs-color));
		text-decoration-color: rgba(var(--#{$prefix}breadcrumbs-color), .66);
		text-underline-offset: .25em;
		&:hover, &:focus {
			color: var(--#{$prefix}secondary);
		}
	}
	a, strong {
		white-space: nowrap;
		display: block;
		max-width: 25ch;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&.breadcrumb {
		.divider {
			color: currentColor;
		}
		.section.active {
			color: var(--#{$prefix}white);
			font-weight: $font-weight-bold;
		}
	}
}