// **********************************************************
// HEADER
body.pageHasSlides {
	.innerHeader {
		padding-bottom: 18rem;
	}
	.content-area.ui.segment {
		margin-top: 0;
		padding-top: 0;
	}
	.slidesWrapper.ui.segment {
		margin-top: -15.5rem;
		z-index: 1;
		position: relative !important;
	}
}


// **********************************************************
// MENU
.instrumentMenu {
	background-color: var(--#{$prefix}dark-primary);
	color: var(--#{$prefix}white);
	margin-top: 1rem;
	padding: 3.5rem 1.5rem 2.5rem 1.5rem !important;
	flex-grow: 1; 
	&__item {
		transition: $transition-base;
		text-decoration: none;
		border-bottom: 1px solid var(--#{$prefix}primary);
		padding-bottom: 1rem;
		display: block;
		font-family: $headings-font-family;
		color: var(--#{$prefix}white);
		margin-bottom: 1.5rem;
		&__icon {
			margin-right: 1rem;
		}
		&:hover, &:focus {
			color: var(--#{$prefix}secondary);
			text-decoration: none;
			border-bottom-color: var(--#{$prefix}secondary);
		}
	}
	& > .row {
		align-items: flex-end;
	}
}


// **********************************************************
// DETAILS
body.contenttype-instrument .bannersHome {
	&.ui.basic.segment {
		background-image: none;
		background-color: var(--#{$prefix}white);
	}
	.ui.cards .ui.card.bannersHome__item {
		background-image: linear-gradient(rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity)), rgba(var(--#{$prefix}primary-rgb), var(--#{$prefix}bg-opacity))), 
			var(--#{$prefix}bgBanners, url('../img/bg-header.jpg'));
	}
}


// **********************************************************
// PUBLICATIONS LIST
.instrumentPublications {
	--#{$prefix}bg-publication: var(--#{$prefix}white);
	--#{$prefix}squaremore-bg: var(--#{$prefix}white);
    --#{$prefix}squaremore-color: var(--#{$prefix}primary);
	&.ui.basic.segment {
		background-color: var(--#{$prefix}light);
	}
}


// **********************************************************
// INTRODUCTION
.instrumentItem__intro {
	display: flex;
	align-items: center;
	flex-direction: column;
	&.ui.segment {
		box-shadow: 0px 0px 26px rgba(var(--#{$prefix}body-color-rgb), 0.16);
		border: none;
		border-radius: .5rem;
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
		background-color: var(--#{$prefix}white);
	}
	&__text {
		font-size: $h5-font-size;
		font-family: $headings-font-family;
		margin-right: 2rem;
		max-width: 75%;
		color: var(--#{$prefix}primary);
	}
	&__btn {
		&.ui.button{
			margin-left: auto;
			flex-shrink: 0;
		}
		svg{
			margin-left: 4.5rem;
		}
	}
	// 576 ixels - 36em
	@include media-breakpoint-up(sm) {
		&.ui.segment {
			flex-direction: row;
			border-radius: 500em;
			padding: 1.5rem 2.5rem 1.5rem 3rem;
		}
	}
}


// **********************************************************
// NEWS 
.relatedNews.relatedNews--instrument {
	&.ui.segment.basic {
		background-color: var(--#{$prefix}white);
	}
	.newsItem.ui.card {
		background-color: transparent;
	}
}
