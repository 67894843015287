// **********************************************************
// EVENTS HOME
.events {
	&__title.header.ui, &__desc {
		color: var(--#{$prefix}primary)
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		&__title.header.ui, &__desc {
			color: var(--#{$prefix}white);
		}
		&__button.ui.button.primary {
			--#{$prefix}primary: var(--#{$prefix}white);
		}
	}
}


// **********************************************************
// EVENT ITEM
.eventItem {
	--#{$prefix}event-color: var(--#{$prefix}primary);
	&.ui.card {
		transition: $transition-base;
		position: relative;
		background-color: var(--#{$prefix}white);
		margin-bottom: 0;

		display: block;
		border-radius: 0;
		box-shadow: none;
		&.eventItem-noImage {
			box-shadow: $box-shadow-sm;
		}
	}
	&__img {
		transition: $transition-base;
		mix-blend-mode: normal;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: $zindex-flat;
		.iu.card > & {
			border-radius: 0;
		}		
	}
	&__text {		
		position: relative;
		aspect-ratio: 1/1;
		width: 100%;
		z-index: $zindex-flat + 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.ui.card &.content {
			border-top: none;
			padding: 1rem;
			border-radius: 0 !important;
		}
	}
	&__location, &__date {
		color: var(--#{$prefix}event-color);
	}
	&__date {
		background-color: var(--#{$prefix}primary);
		color: var(--#{$prefix}white);
		border-radius: 500em;
		font-size: calc(.75rem * var(--#{$prefix}fs-scale));
		font-weight: $font-weight-bold;
		display: inline-flex;
		padding: .35em .65em;
		white-space: nowrap;
		line-height: 1;
		border-radius: 500em; 
	}
	&__location {
		text-transform: uppercase;
		font-size: calc(#{$font-size-sm} * 	var(--#{$prefix}fs-scale));;
		margin-top: 0.5rem !important;
		margin-bottom: 1rem;
	}
	&__link {
		color: var(--#{$prefix}event-color);
		text-decoration: none;
		display: block;
		font-size: calc(#{$h5-font-size} * 	var(--#{$prefix}fs-scale));
		margin: 1.5rem 0 0 0;
		font-weight: $font-weight-normal;
		font-family: $font-family-sans-serif;
		line-height: $line-height-sm;
		&__link {
			text-decoration: none; 
			&:hover, &:focus {
				text-decoration: underline;
				text-decoration-color: var(--#{$prefix}secondary);
			}
			&:focus {
				outline: 1px dotted var(--#{$prefix}primary);
			}
		}
	}	
	&:hover, &:focus-within {
		--#{$prefix}evemt-color: var(--brand-primary);
		background-color: var(--#{$prefix}secondary);
		.eventItem__img {
			mix-blend-mode: multiply;
			opacity: .33;
		}
	}
	&__desc {
		font-size: calc(#{$font-size-base} * 	var(--#{$prefix}fs-scale));
		color: var(--#{$prefix}body-color);
		margin-top: 1rem !important;
		padding-top: 1rem;
		border-top: 1px solid var(--#{$prefix}light);
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}
	&-hasImage {
		--#{$prefix}event-color: var(--#{$prefix}white);
		.eventItem__text {
			justify-content: flex-end;
			&.content {
				background-color: rgba(var(--#{$prefix}primary-rgb), .45);
			}
		}
		&:hover, &:focus-within {
			--#{$prefix}evemt-color: var(--brand-primary);
		}
		.eventItem__link:hover, .eventItem__link:focus {
			color: var(--#{$prefix}event-color);
		}
	}
}

// **********************************************************
// INTERIOR
body.eventslisting {
	#view {
		position: relative;
		z-index: $zindex-flat;
	}
	.content-area.ui.segment {
		padding-top: 0;
		margin-top: 0;
	}
}
.eventPage {
    &__img {
        width: 100%;
        height: auto;
		&__wrapper {
			margin: -6.5rem 0 2.5rem 0;
			position: relative;
			z-index: $zindex-flat;
		}
		&__caption {
			color: var(--#{$prefix}primary);
			font-family: $headings-font-family;
			font-size: $font-size-sm;
			line-height: $line-height-sm;
			margin-top: .5rem;
		}
    }
    &-hasImage {
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: $zindex-flat;
            left: 0;
            top: 0;
            width: 100%;
            aspect-ratio: 1 / 1;
            background-color: rgba(var(--#{$prefix}primary-rgb), .4);
        }
    } 
    &__link {
        text-decoration: none;
        color: var(--#{$prefix}primary);
    }
    &__date {
        position: absolute;
        right: .5rem;
        bottom: 0;
        transform: translateY(50%);
        z-index: $zindex-flat;
    }
    &__location {
        --#{$prefix}text-opacity:.55;
        body.highContrast {
            --#{$prefix}text-opacity: 1;
        }
    }
	.eventInfo__geolocation {
		margin-right: -1em;
	}
    .shareBox__btn {
        background-color: var(--#{$prefix}share);
        color: var(--#{$prefix}white);
		position: relative;
		z-index: $zindex-flat + 2;
    }
	
}


// **********************************************************
// ASIDE INFO
.eventInfo {
	&.ui.segment { 
		position: relative !important;
		z-index: $zindex-flat;
		box-shadow: none;
		border: none;
		background-color: var(--#{$prefix}light);
		color: var(--#{$prefix}primary);
		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			margin-top: -6.5rem;
		}
	}
	.ui.sub.header {
		font-size: 1rem;
		text-transform: none;
		color: var(--#{$prefix}primary);
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-bold;
	}
	&__field {
		display: flex;
		margin-bottom: 0.5rem;
		align-items: baseline;
		& > * {
			flex-grow: 1;
		}
		.ui.sub.header, .ui.list {
			font-size: 0.875rem;
		}
		& > .ui.sub.header {
			flex: 0 0 34%;
			color: var(--#{$prefix}primary);
			padding-right: 0.75rem;
			margin-top: 0;
			font-size: .875rem;
			text-transform: uppercase;
		}
		.ui.list {
			margin-top: 0;
			flex: 0 0 66%;
		}
	}
	.ics-download {
		display: inline-flex !important;
		align-items: center;
		.icon {
			height: 1.5rem !important;
		}
	}
	.leaflet-wrapper {
		margin-left: -1em;
		margin-right: -1em;
		.leaflet-container {
			height: 25rem; // 400px
			@supports (aspect-ratio: 4/3){
				height: auto !important;
				aspect-ratio: 4/3;
			}
		}
	}

	// 1600 pixels - 100em
	@include media-breakpoint-up(xxl) {
		&__field > .ui.sub.header {
			flex-basis: 40%;
		}
	}
}

// **********************************************************
// PAGE CONTENT
body.contenttype-event {
	.content-area.ui.segment.basic  {
		// 768 pixels - 48em
		@include media-breakpoint-up(lg) {			
			margin-top: 0;
			padding-top: calc(1rem + 1em);
		}
	}
}

// **********************************************************
// VIEW SWITCHER
.calendarSwitch {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, -50%);
	z-index: $zindex-flat + 1;
	&.ui.buttons{ 
		box-shadow: $box-shadow-sm !important;
		background-color: transparent;
		border-radius: var(--#{$prefix}border-radius-2xl);
		.button.ui {
			font-size: $h5-font-size;
			background-color: var(--#{$prefix}white);
			line-height: 1;
			box-shadow: none;
			&.active {
				background-color: var(--#{$prefix}primary);
				color: var(--#{$prefix}white);
			}
			&:first-child {
				border-top-left-radius: var(--#{$prefix}border-radius-2xl);
				border-bottom-left-radius: var(--#{$prefix}border-radius-2xl);
			}
			&:last-child {
				border-top-right-radius: var(--#{$prefix}border-radius-2xl);
				border-bottom-right-radius: var(--#{$prefix}border-radius-2xl);
			}
			&:hover, &:focus {
				background-color: var(--#{$prefix}secondary);
				color: var(--#{$prefix}primary);
			}
		}
	}
}

// **********************************************************
// EVENT LIST
.searchList {
	&__timeButtons {
		flex: 0 0 100%;
		width: 100%;
		display: flex;
		margin-bottom: 1rem;
		.field + .field {
			margin-left: 0.5rem !important;
		}
	}
	&__label {
		flex: 0 0 100%;
		display: block;
		margin-bottom: 0.25rem;
		color: var(--#{$prefix}body-color);
		font-size: .875rem;
		font-weight: 500;
	}
	&__dateSelect {
		flex-wrap: wrap;
		padding: 0 .5em;
	}
	&__dateSelect, .DateRangePicker, .DateRangePickerInput__showClearDates {
		width: 100%;
	}
	&-events.ui.form .fields > .field {
		width: 100%;
		margin-bottom: 1rem;
	}
	.DateRangePicker {
		display: block;
		margin-bottom: 2rem;
	}
	.DateRangePickerInput__showClearDates {
		padding-right: 0;
	}
	.DateInput {
		width: 100%;
		input[type="text"] {
			border: none;
			background-color: var(--#{$prefix}light);
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			&::placeholder {
				color: var(--#{$prefix}primary) !important;
			}
		}
	}
	.DateRangePickerInput_arrow {
		width: 100%;
		display: block;
		text-align: center;
		margin: .25rem 0;
	}
	.DateRangePicker_picker {
		z-index: 4;
	}
	.DateRangePickerInput_clearDates {
		top: auto;
		transform: none;
		bottom: 0.5rem;
		border-radius: 0;
	}

	// 576 ixels - 36em
	@include media-breakpoint-up(sm) {
		.DateRangePickerInput__showClearDates {
			display: flex;
			align-items: center;
		}
		.DateRangePickerInput_arrow {
			flex-shrink: 0;
			width: 2.5rem;
		}
	}

	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		&.ui.form .fields {
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {		
		&.ui.form .fields {
			flex-wrap: nowrap;
		}
		&__timeButtons {
			flex: 0 0 auto;
			width: auto;
			margin-bottom: 0;
			margin-top: 2rem;
		}
		&__textInput{
			&.field {
				padding-left: 1.5rem !important;
				margin-left: 1.25rem !important;
				position: relative;
			}
			&::before {
				content: '';
				display: block;
				height: 3.75rem;
				border-left: 1px solid var(--#{$prefix}secondary);
				position: absolute;
				left: 0;
				bottom: 0;
		}}
		&-events.ui.form .fields > .field {
			width: 13rem;
			flex: 1 0 auto;
			margin-bottom: 1rem;
		}
		.DateInput {
			width: 12rem;
		}
		&__dateSelect, .DateRangePicker, .DateRangePickerInput__showClearDates, .ui.input.date-input, .SingleDatePicker, .SingleDatePickerInput, .DateInput_input {
			width: auto;
		}
		&__dateSelect {
			flex: 0 0 auto;
			display: block;
		}
		&-events.ui.form .fields > .field {
			margin-bottom: 0;
		}
		.DateRangePicker {
			margin-bottom: 0;
		}
		.DateRangePickerInput_clearDates {
			top: 50%;
			bottom: auto;
			transform: translateY(-50%);
			right: auto;
			left: 100%;
		}
	}

	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		.DateInput {
			width: 14rem;
		}
		&.searchList-showCalendar {
			transform: translateY(-4rem);
			border-radius: var(--sa-border-radius-2xl);
		}
	}
}

.newsItem__type-event.newsItem__type {
	--sa-type-bg: var(--#{$prefix}primary);
	font-weight: $font-weight-semibold;
	&.ui.label {
		font-size: $font-size-sm;
	}
}
.eventItem__location.eventItem__location--list {
	font-weight: $font-weight-bold;
	margin-top: 0 !important;
}

// **********************************************************
// EVENT ITEM LIST
.newsItem--event {
	&.ui.fluid.card {
		max-width: 30rem;
		margin-left: auto;
		margin-right: auto;
	}
	&.newsItem-noImage.ui.card {
		border-top: none;
		border-bottom: none;
		box-shadow: $box-shadow-sm;
		padding: 1rem 1rem 0 1rem;
		&:hover, &:focus-within {
			background-color: var(--#{$prefix}white);
			.newsItem__img__wrapper {
				background-color: transparent !important;
			}
		}
		// 768 pixels - 48em
		@include media-breakpoint-up(md) {
			height: 100%;
			.newsItem__text {
				flex-grow: 1;
			}
		}
	}
	.newsItem__type-event .label.ui {
		font-size: calc( .8rem * var(--#{$prefix}fs-scale) );
	}
	.socialShare.ui.button {
		background-color: var(--#{$prefix}brand-share);
		position: relative;
		top:auto;
		right: auto;
		box-shadow: none;
		flex-shrink: 0;
		.icon.share-nodes {
			background-image: url("data:image/svg+xml,%3Csvg id='share' xmlns='http://www.w3.org/2000/svg' width='19.059' height='20.33' viewBox='0 0 19.059 20.33'%3E%3Cg%3E%3Cpath d='M31.485,13.182a3.56,3.56,0,0,0-2.865,1.459l-5.632-2.884a3.371,3.371,0,0,0-.105-2.333l5.894-3.547a3.554,3.554,0,1,0-.865-2.3,3.541,3.541,0,0,0,.249,1.285L22.249,8.416a3.567,3.567,0,1,0,.225,4.417l5.614,2.875a3.525,3.525,0,0,0-.175,1.049,3.574,3.574,0,1,0,3.574-3.574Z' transform='translate(-16)' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E%0A"); 
		}
	}
	&.newsItem-noImage {
		.newsItem__text {
			display: flex;
			flex-direction: column;
		}
		.socialShare.ui.button {
			margin: auto 0 0 auto;
		}
	}
	&.newsItem {
		&:hover, &:focus-within {
			background-color: var(--#{$prefix}white);
			.newsItem__link {
				text-decoration: underline;
			}
		}
	}
}

// **********************************************************
// CALENDAR
.calendarWrapper {
	width: 100%;
	max-width: 80rem;// 1280px
	margin-left: auto;
	margin-right: auto;
	height: auto;
	&.ui.segment {
		margin-left: auto;margin-right: auto;
	}
	.rbc-month-view {
		aspect-ratio: 1 / 1;
	}
	.rbc-toolbar button {
		color: var(--#{$prefix}body-color);
		border:none;
		background-color: var(--#{$prefix}light);
		font-family: $headings-font-family;
		font-weight: $font-weight-bold;
		font-size: var(--#{$prefix}btn-font-size);
		border-radius: var(--#{$prefix}border-radius-2xl);
		.rbc-btn-group > button:not(:first-child):not(:last-child) {
			border-radius: 0;
		}
		& + button {
			border-left: 1px solid var(--#{$prefix}white);
		}
	}
	.rbc-toolbar-label {
		font-family: $headings-font-family;
		font-weight: $font-weight-bold;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		font-size: clamp($h4-font-size, 4vw, $h1-font-size);
		flex: 0 0 100%;
		width: 100%;
	}
	.rbc-btn-group {		
		button {
			cursor: pointer;
			&:hover, &:focus {
				background-color: var(--#{$prefix}secondary);
			}
		}
	}
	.rbc-day-bg {
		font-family: $headings-font-family;
		& + .rbc-day-bg {
			border-color: rgba(var(--#{$prefix}body-bg-rgb), .05);
		}
	}
	.rbc-header, .rbc-month-view, .rbc-month-row + .rbc-month-row {
		border-color: rgba(var(--#{$prefix}body-bg-rgb), .05);
	}
	.rbc-header {
		font-size: clamp($h6-font-size, 2vw, $h4-font-size);;
		font-weight: $font-weight-normal;
		font-family: $headings-font-family;
	}
	.rbc-date-cell {
		button {
			font-family: $headings-font-family;
			font-weight: $font-weight-semibold;
			color: var(--#{$prefix}primary);
		}
		&.rbc-off-range {
			color: var(--#{$prefix}primary);
			opacity: .20;
		}
	}
	.rbc-day-bg{
		&.rbc-off-range-bg {
			background-color: transparent;
		}
		
	}

	// Event Link 
	.rbc-event {
		font-size: .75rem;
		background-color: var(--#{$prefix}secondary);
		border-radius: 50rem;
		padding: 2px 1rem;
		color: var(--#{$prefix}primary);
		a {
			color: var(--#{$prefix}primary);
			text-decoration: none;
			width: 0.5rem;
			height: 0.5rem;
			text-indent: -1000rem;
			overflow: hidden;
			display: block;
			&:hover, &:focus {
				color: var(--#{$prefix}white);
			}
		}
		&:focus-within, &:hover {
			background-color: var(--#{$prefix}primary);
			color: var(--#{$prefix}white);
			a {
				color: var(--#{$prefix}white);
			}
		} 
		svg {
			display: none;
		}
	}
	// 576 pixels - 36em
	@include media-breakpoint-up(sm) {
		height: 55rem;
		.rbc-month-view {
			aspect-ratio: unset;			
		}
	}
	// 768 pixels - 48em
	@include media-breakpoint-up(md) {
		.rbc-event {
			a{
				text-indent: 0;
				overflow: visible;
				width: auto;
				height: auto;
				display: inline;
			}
		}
		.rbc-date-cell {
			font-size: clamp($h5-font-size, 3vw, $h2-font-size);
		}
	}
	// 1280 pixels - 80em
	@include media-breakpoint-up(xl) {
		.rbc-event {
			font-size: $font-size-sm;
		}
	}
}