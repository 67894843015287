// Set body properties with CSS custom variables
body {	 	
	color: var(--#{$prefix}body-color); 
	background-color: var(--#{$prefix}body-bg);
	font-size: calc(var(--#{$prefix}body-font-size) * var(--#{$prefix}fs-scale) );
}

// Links

a {
	color: var(--#{$prefix}link-color); 
  
	&:hover, &:focus {
	  color: var(--#{$prefix}link-hover-color); 
	}
  }