// **********************************************************
// LAYOUT
.portalHeader {
    --#{$prefix}link-color: var(--#{$prefix}white);
    --#{$prefix}link-hover-color: var(--#{$prefix}secondary);
    --#{$prefix}bgHeader: transparent;
    
    background-color: var(--#{$prefix}bgHeader) !important;
    &.ui.segment {
        position: fixed !important;
        right: 0;
        left: 0;
        top: 0;
        z-index: $zindex-flat + 4;
        body.frontpage & {
            z-index: 99;
            margin-bottom: 0; 
            left: 0;
            top: 0;
            right: 0;
        }
    }
    .navbar-toggler {
        border: none;
    }
    .navbar-toggler-icon {
        --#{$prefix}navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14'%3E%3Cpath id='Menu' d='M-6160,14V12h10v2Zm0-6V6h16V8Zm0-6V0h16V2Z' transform='translate(6160)' fill='%23fff'/%3E%3C/svg%3E");
        width: 1rem;
        height: 0.875rem;
    }
    &.affix {
        --#{$prefix}bgHeader: var(--#{$prefix}primary);
        &.ui.segment {
            box-shadow: $box-shadow;
        }
        .portalHeader__top {
            display: none !important;
        }
        .logoHeader__img {
            width: 3.25rem;
            clip-path: polygon(0px 0px, 100% 0px, 100% 28px, 0px 28px);            
        }
        // 992 pixels - 62em
        @include media-breakpoint-up(lg) {
            .ui.grid > .row {
                padding-top: 0;
                padding-bottom: 0;
            }
            .ui.menu {
                margin: 0;
            }
            .logoHeader{
                padding-bottom: 0;
                &__img {
                    width: 4.5rem;
                    clip-path: polygon(0px 0px, 100% 0px, 100% 36px, 0px 36px);
                    margin-top: 1rem;       
                }
            }
        }
    }
    .offcanvas-xl {
        --#{$prefix}link-color: var(--#{$prefix}primary);
    }
    // 1280 pixels - 75em
    @include media-breakpoint-up(xl) {
        .offcanvas-body, .offcanvas-xl {
            height: 100%;
            visibility:visible !important;
        }
    }

    // With Edit Bar
    body.has-toolbar &.ui.segment {
        top: 100px;
        // 768 pixels - 48em
        @include media-breakpoint-up(md) {
            top: 0;
            left: 80px;
        }
    }
}


// **********************************************************
// TOP HEADER
.portalHeader__top {
    // 768 pixels - 48em
    @include media-breakpoint-up(md) {
        .headerCols1.column {
            width: 25% !important;
        }
        .headerCols2.column {
            flex-grow: 1;
        }
    }
}

// **********************************************************
// LOGO
.logoHeader {
    padding-top: 1rem;
    padding-bottom: 1rem;
    &__img {
        width: 5.25rem;
        height: auto;
    }
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        &__img {
            width: 7.5rem;
        }
    }
}

// **********************************************************
// OFFCANVAS
.offcanvas__logo {
    height: 5rem;
    width: auto;
}


// **********************************************************
// DIRECT ACTIONS
.directActions {
    --#{$prefix}btn-color: var(--#{$prefix}primary);
    --#{$prefix}link-color: var(--#{$prefix}primary);
    --#{$prefix}directActions-color: var(--#{$prefix}primary);
    --#{$prefix}directAcions-bg: var(--#{$prefix}light);
    transition: $transition-base;
    transform: translateX(calc(100% - 3.5rem));
    position: fixed;
    z-index: 3;
    right: 0;
    top: 9rem;
    body.has-toolbar & {
        top: calc(9rem + 100px);
        // 768 pixels - 48em
        @include media-breakpoint-up(md) {
            top: 9rem;
        }
    }
    &.ui.segment {
        position: fixed !important;
        padding: 0;
        border: none;
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
    }
    @media (pointer:fine) {
        &:hover, &:focus-within{
            transform: translateX(0);
        }
    }
    @media (pointer:coarse) {
        &.showSideBar {
            transform: translateX(0);
        }
    }
    &__item {
        display: flex;
        & + .directActions__item {
            .directActions__toggler {
                border-top: 1px solid rgba(var(--#{$prefix}white-rgb) , .33);
            }
            .directActions__content {
                border-top: 1px solid var(--#{$prefix}gray-300);
            }
        }
    }
    &__toggler, &__toggler.ui.button {
        background-color: var(--#{$prefix}directActions-color);
        color: var(--#{$prefix}directAcions-bg);
        display: inline-flex;
        height: 3.5rem;
        width: 3.5rem;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
    }
    &__content {
        flex-grow: 1;
        height: 3.5rem;
        background-color: var(--sa-directAcions-bg);
        display: flex;
        align-items: center;
        padding: 0 0 0 1rem;
    }
    .ui.buttons {
        &, .ui.button {
            border: none;
        }
    }
    &__item-toggler {
        --#{$prefix}directActions-color: var(--#{$prefix}dark);
        display: none;
        background-color: var(--#{$prefix}directActions-color);
        .directActions__toggler.ui.button {
            border-radius: 0;
            padding: 0;
        } 
        @media (pointer:coarse) {
            display: flex;
        }
    }
    .constrastToggle {
        &.ui.button.basic {
            width: 2.5rem;
            height: 2.5rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 0;
        }
    }
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        top: 12rem;
    }

    // HIDE when user is editing
    body.section-edit &, body.section-add &, body.view-contentsview & {
        display: none !important;
    }
}
.directActions__toggler__icon {
    transition: transform .5s;
    .showSideBar & {
        transform: rotate(180deg);
    }
}


// **********************************************************
// SEARCH
.btnSearchToggle.ui.button {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: 1.25rem;
    color: var(--#{$prefix}white);
    padding: .25rem .5rem;
    margin-left: 1rem;
    &:hover, &:focus {
        color: var(--#{$prefix}secondary);
        background-color: transparent;
    }
}


// **********************************************************
// LANGUAGES
.langSelector {
    --#{$prefix}link-color: var(--#{$prefix}primary);
    --#{$prefix}link-hover-color: var(--#{$prefix}dark);
    &.ui.dropdown .menu {
        left: auto;
        right: 0;
        & > .item {
            padding: 0 !important;
            a {
                padding: 0.6875rem 1.125rem;
                display: block;
            }
        }
    }
    .divider {
        text-transform: uppercase;
        font-size: calc(.875rem  * var(--#{$prefix}fs-scale));;
    }
    a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: calc(#{$font-size-sm}  * var(--#{$prefix}fs-scale));
    }
}

// **********************************************************
// LOGIN
.loginMenu {
    &.ui.menu {
        margin-top: 0;
    }
}

// **********************************************************
// INNER PAGE HEADER
.innerHeader {
    body.frontpage & {
        display: none !important;
    }
    position: relative;
    z-index: $zindex-flat;
    top: 0;
    left: 0;
    width: 100%;
    padding: calc(5rem + var(--#{$prefix}headerHeight, 8rem)) 0 2rem 0;
    background: linear-gradient(to bottom, $dark-primary 0%, var(--#{$prefix}primary) 100%);
    font-family: var(--#{$prefix}headings-font-family);
    flex-shrink: 0;
    &__title {
        text-wrap: balance;
        color: var(--#{$prefix}white);
        &.documentFirstHeading {
            border-bottom: none;
            &::before{
                content: none;
            }
        }
    }
    &__description{
        font-family: $headings-font-family;
        font-size: $h5-font-size;
        color: var(--#{$prefix}white);
        line-height: $headings-line-height;
    }
    .container.d-flex {
        position: relative;
        z-index: $zindex-flat + 1;
    }
    body.headerNegativeBottom & {
        padding-bottom: 2rem + 4.5rem;
    }
    // 992 pixels
    @include media-breakpoint-up(lg) {
        min-height: 36rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 3.5rem;
        body.headerNegativeBottom & {
            padding-bottom: 2rem + 4.5rem;
        }
        &__text {
            max-width: 75%;
        }
    }
    // 1280 pixels - 80em
    @include media-breakpoint-up(xxl) {
        &__text {
            max-width: 66%;
        }
        &__description {
            font-size: $h3-font-size;
        }
    }
} 

.bgArc {
    min-height: 30rem;
    overflow: hidden; 
    --#{$prefix}bgHeader: var(--#{$prefix}bgHeader-xs, url(../img/bg-header-xs.jpg));
    &::before {
        content: '';
        display: block;
        position: absolute;
        inset: auto 0 0 0;
        aspect-ratio: 1 / 1;
        background-image: linear-gradient(rgba(var(--#{$prefix}primary-rgb), 0.60), rgba(var(--#{$prefix}primary-rgb), 0.60)), var(--#{$prefix}bgHeader);
        background-repeat: no-repeat;
        background-position: 0 0, center center;
        background-size: auto, cover;
        clip-path: circle(100% at 100% 100%); 
    }
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        --#{$prefix}bgHeader: var(--#{$prefix}bgHeader-md, url(../img/bg-header-md.jpg));
        &::before { 
            top:0;
            right: 0;
            width: 67%;
            height: 100%;
            left: auto;
            clip-path: circle(80% at 80% 100%);
            aspect-ratio: unset; 
        }
    }
    // 1600 pixels - 100em
    @include media-breakpoint-up(xxl) {
        --#{$prefix}bgHeader: var(--#{$prefix}bgHeader-lg, url(../img/bg-header-lg.jpg));
    }

    body.no-bgArc & {
        &.innerHeader {
            min-height: 36rem;
        }
        &::before {
            content: none;
        }
    }
}
