.squareMore {
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--#{$prefix}squaremore-bg, var(--#{$prefix}white));
    color: var(--#{$prefix}squaremore-color, var(--#{$prefix}primary) );
    box-shadow: $box-shadow;
    font-size: 1.5rem;
}

.bg-working {
    background-image: linear-gradient(rgba(var(--#{$prefix}primary-rgb), .66) 0, rgba(var(--#{$prefix}primary-rgb), .66) 100%), url(../img/bg-news-circle.svg), url(../img/bg-working.jpg);
    background-repeat: no-repeat;
    background-size: auto, 50%, cover;
    background-position: 0 0, right bottom, center center;
    background-color: var(--#{$prefix}primary);
    background-blend-mode:normal, normal, lighten;
}

.pageDescription { 
    color: var(--#{$prefix}primary);
    font-size: $h4-font-size;
    line-height: $line-height-base;
}

.maxContent {
    max-width: 62rem;
    margin-left: auto;
    margin-right: auto;
}

// **********************************************************
// BLOCKQUOTE
.pageBlocks blockquote {
    --#{$prefix}blockquote-accent: var(--#{$prefix}primary);
    --#{$prefix}blockquote-radius: 0;
    --#{$prefix}blockquote-bg: var(--#{$prefix}light);
    position: relative;
    background-color: var(--#{$prefix}blockquote-bg);
    border: none;
    padding: 1.5rem 2rem 1.5rem 4rem;
    border-radius: var(--#{$prefix}blockquote-radius);
    font-family: $headings-font-family;
    color: var(--#{$prefix}primary);
    max-width: 34rem;
    margin: 1.5rem auto 1.5rem 0;
    &, &.blockquote {
        font-size: $h5-font-size;    
    }
    &::before {
        content: '';
        display: block;
        width: .125rem;
        top: 1.5rem;
        bottom: 1.5rem;
        left: 2.5rem;
        background-color: var(--#{$prefix}primary);
        position: absolute;
        z-index: 1;
    }
    .blockquote-footer {
        font-size: var(--#{$prefix}fs-h6);
        padding-top: 0.5rem;
    }
    &-primary {
        --#{$prefix}blockquote-accent: var(--#{$prefix}primary);
    }
    &-secondary {
        --#{$prefix}blockquote-accent: var(--#{$prefix}secondary);
    }
    &-rounded {
        --#{$prefix}blockquote-radius: var(--#{$prefix}border-radius);
    }
    &-negative {
        --#{$prefix}blockquote-bg: var(--#{$prefix}blockquote-accent);
        --#{$prefix}link-color: $white;
        color: var(--#{$prefix}white);
        .blockquote-footer {
            color: var(--#{$prefix}white);
        }
        &::before {
            background-color: var(--#{$prefix}white);
        }
    }
    &-xl {
        border-radius: var(--#{$prefix}border-radius-2xl);
    }
}
