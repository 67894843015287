// **********************************************************
// NAVIGATION TOGGLER
.hamburger-wrapper  {
    .hamburger::before, .hamburger::after, .hamburger-inner::after, .hamburger-inner::before {
        background-color: var(--#{$prefix}hamburger-color);
        border-radius: 0;
    }
    .hamburger{ 
        &::before {
            width: 66%; 
        }
		&.is-active {
			--#{$prefix}hamburger-color: var(--#{$prefix}primary);
		}
    }
}

// **********************************************************
// NAVIGATION MENU
.mnPrincipal {
    .ui.menu {
        font-family: $headings-font-family;
    }
    .ui.menu .item {
        font-weight: $font-weight-semibold !important;
    }
    .ui.inline.dropdown {
        color: var(--#{$prefix}nav-link-color);
        .text {
            font-weight: $font-weight-semibold;
            &:hover, &:focus, &:active {
                color: var(--#{$prefix}nav-link-hover-color);
            }
        }
    }
    .ui.secondary.pointing.menu .dropdown.item:hover, 
    .ui.secondary.pointing.menu .link.item:hover, 
    .ui.secondary.pointing.menu a.item:hover {
        color: var(--#{$prefix}nav-link-hover-color);
    }
   
    // MOBILE MENU 
    .mobile-menu-nav {
        --#{$prefix}nav-link-color: var(--#{$prefix}primary);
        --#{$prefix}nav-link-hover-color: var(--#{$prefix}dark);
        align-items: flex-start;
        padding-top: 14rem;
        &::before {
            content: '';
            display: block;
            position: absolute;
            height: 14rem; 
            left: 0;
            top: 0rem;
            width: 100%;
            background-color: var(--#{$prefix}white);
            z-index: $zindex-flat;
        }
        .ui.menu .item {
            color: var(--#{$prefix}nav-link-color);
        }
        .ui.pointing.secondary.stackable.menu {
            align-items: flex-start;
            text-align: left;
            .item {
                display: block;
                padding: .5rem;
                &.active {
                    border-color: var(--#{$prefix}secondary);
                }
            }
        }
        .ui.dropdown .menu {
            position: static;
            & > .item {
                padding: .5rem 0 !important;
            }
        }
        
        // TABLET MENU
        // 768 pixels - 48em
        @include media-breakpoint-up(md) {
            .ui.pointing.secondary.stackable.menu {
                flex-direction: column;
                .item {
                    align-self: flex-start;
                }
            }
        }
    }

    // SECOND LEVEL
    &__second {
        a {
            color: var(--#{$prefix}nav-link-color);
            text-decoration: none;
            text-transform: none !important;
            font-size: $font-size-base;
        }
        .mnPrincipal.navigation .secondary &.menu {            
            font-family: $headings-font-family;
            border:none !important;
            & > li.item {
                a {
                    font-size: $font-size-base;
                    text-decoration: none;
                }
            }
        }
        .mnPrincipal.navigation .mobile-menu .secondary &.menu {
            box-shadow: none !important;
        }
        &__list {
            flex-grow: 1;
        }
        // 992 pixels - 62em
        @include media-breakpoint-up(lg) {
            &__wrapper {
                display: flex;
            }
        }
        // 1920 pixels - 120em
        @include media-breakpoint-up(xxxl) {
            a {
                font-size: calc( #{$font-size-base} * var(--#{$prefix}fs-scale) );
            }
        }
    }
    .navigation .ui.secondary.pointing.menu & .item {
        text-transform: none;
    }
    & .desktop-menu-nav.ui.secondary.pointing.menu .header .active.item {
        color: var(--#{$prefix}primary); 
        border-bottom: 2px solid var(--#{$prefix}secondary);
    }
    &__banners {
        border-top: 1px solid var(--#{$prefix}border-color);
        padding-top: 2rem;
        &__item {
            &__icon {
                display: inline-flex;
                width: 2.5rem; 
                height: 2.5rem;
                align-items: center;
                justify-content: center;
                font-size: 1.25rem;
                border-radius: 50%;
                margin-right: 1rem;
                background-color: var(--#{$prefix}light);
            }
        }
        // 992 pixels - 62em
        @include media-breakpoint-up(lg) {
            border-top: none;
            border-left: 1px solid var(--#{$prefix}border-color);
            padding-left: 3rem ;
            padding-top: 0;
        }
    }


    // DESKTOP MENU
    .desktop-menu-nav {
        --#{$prefix}nav-link-color: var(--#{$prefix}white);
        --#{$prefix}nav-link-hover-color: var(--#{$prefix}secondary);

        justify-content: center;

        .dropdown .menu{
            --#{$prefix}nav-link-color: var(--#{$prefix}primary);
            --#{$prefix}nav-link-hover-color: var(--#{$prefix}dark);
        }       
        &.ui.large.menu {
            font-size: $font-size-base;
            .item {
                position: static;
            }
        }
        &.ui.menu .item {
            color: var(--#{$prefix}nav-link-color);
        }
        &.ui.secondary.menu .dropdown.item  {
            & > .menu{
                border-radius: 0;
                margin-top: 0;
            } 
        }
        &.ui.secondary.pointing.menu .item {
            border-bottom: 2px solid transparent;
            &.active {
                color: var(--#{$prefix}nav-link-color); 
                border-bottom-color: var(--#{$prefix}secondary);           
            }
            svg {
                font-size: .75rem;
            }
        } 
        .mnPrincipal__second__list {
            columns: 16rem 3;
        }
        .container {
            display: flex;
        }

    }
    
    // 1280 pixels - 80em
    @include media-breakpoint-up(xl) {
        .header {
            max-width: 62rem;
            margin-left: auto !important;
            margin-right: auto !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        &__wrapper {
            position: static !important;
        }
        &__second__wrapper{
            padding-bottom: 3rem;
            max-width: 62rem;
            margin-left: auto;
            margin-right: auto;
        }
        &.navigation .ui.large.menu .item{
            padding-top: .5rem;
            padding-bottom: 0.5rem;
        }        
    }

    // 1920 pixels - 120em
    @include media-breakpoint-up(xxxl) {
        .desktop-menu-nav {          
            &.ui.large.menu {
                font-size: calc( #{$font-size-base} * var(--#{$prefix}fs-scale) );
            }
        }
    }

}

.mnXZPrincipal .ui{    
    font-family: $font-family-sans-serif;

	&.secondary.pointing.menu .item {
		color: var(--#{$prefix}nav-link-color);
		&:hover, &:focus {
			color: var(--#{$prefix}secondary);
		}
		&.active {
			color: var(--#{$prefix}nav-link-color);
		}
	}
	// 992 pixels - 62em
	@include media-breakpoint-up(lg) {
		--#{$prefix}nav-link-color: var(--#{$prefix}white);
		&.large.menu {
			font-size: 1rem;
		}
		&.secondary.menu .item.active {
			border-bottom-color: var(--#{$prefix}secondary);
			&:hover, &:focus {
				color: var(--#{$prefix}secondary);
				border-bottom-color: var(--#{$prefix}tertiary);
			}
		}
	}
}


.mnPrincipal .mobile-menu .ui {
    --#{$prefix}nav-link-color: var(--#{$prefix}primary);
}

// **********************************************************
// TOP ACTIONS MOBILE
.topActionsMobile {
    display: flex; justify-content: center; width: 100%; margin-top: 3rem; list-style-type: none; padding:0; margin-bottom: 0;
    a { color: var(--#{$prefix}primary);}
}