// **********************************************************
// PUBLICATION ITEM
.publicationItem {
    --#{$prefix}translate: 0 , 50%;
	&.ui.card {
		background-color: var(--#{$prefix}bg-publication, var(--#{$prefix}light) );
		position: relative;
		padding: 1.5rem;
		box-shadow: none;
		border-radius: 0;
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
		.content {
			border-top: none !important;
			padding: 0;
		}
	}
	&__title {
		font-size: $h5-font-size;
		font-weight: $font-weight-bold;
	}
    &__link {
        text-decoration: none;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 3.5rem;
            height: 3.5rem;
            bottom: 0;
            right: 1.5rem;
            transform: translateY(50%);
            z-index: $zindex-flat + 3;
        }
    }
	&__desc {
		font-size: $h5-font-size;
		font-family: $headings-font-family;
		color: var(--#{$prefix}primary);
	}
    &__info {
        padding-right: 3.5rem + 1.5rem;
    }
    .squareMore {
        transition: $transition-base;
        position: absolute;
        bottom: 0;
        right: 1.5rem;
        transform: translate(var(--#{$prefix}translate));
        z-index: $zindex-flat;
    }
    &__type {
        background-color: var(--#{$prefix}type-bg);
        color: var(--#{$prefix}white);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        border-radius: 1.25rem;
        padding: .5rem 1rem;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin: 1rem 1rem 1rem 0rem !important;
    }
    &__subText {
        font-size: $font-size-sm;
        max-width: 18rem;
        color: var(--#{$prefix}primary);
    }
    &:hover, &:focus-within{
        --#{$prefix}translate: .5rem , 50%;
        --#{$prefix}squaremore-bg: var(--#{$prefix}primary);
        --#{$prefix}squaremore-color: var(--#{$prefix}secondary);
    }
}