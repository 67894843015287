.portalFooter {
    --#{$prefix}link-color: var(--#{$prefix}white);
    --#{$prefix}link-hover-color: var(--#{$prefix}secondary);
    &.ui.segment {
        margin: 0;
        padding: 0;
    }
}

// 992 pixels - 62em
@include media-breakpoint-up(lg) {
    .footerColumn1 {
        order: 2;
    }
    .footerColumn2 {
        order:1;
    }
    .footerColumn3 {
        order: 3;
    }
}

// **********************************************************
// PATROS
.secPatros {
    &.ui.secondary.segment {
        background-color: var(--#{$prefix}light);
        box-shadow: none;
        margin: 0;
        border: none;
    }
}
.patros {
    &__link.ui.image img {
        width: 10rem; // 208px
        margin: 1rem 2rem;
        // 1280 pixels - 80em
        @include media-breakpoint-up(xl) {
            width: 15rem;
        }
    }
}

// **********************************************************
// LOGO
.logoFooter {
    max-width: 24rem;
    &__img {
        height: 5rem;
        width: auto;
    }
    // 992 pixels - 62em
    @include media-breakpoint-up(lg) {
        &__img {
            height: 6.5rem;
        }
    }
}
.logo-ecityclic img {
    width: 4.5rem; height: auto;
  }

// **********************************************************
// XXSS
 .footerXXSS {
    a {
        line-height: 1;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: var(--#{$prefix}white);
        color: var(--#{$prefix}primary);
        flex: 0 0 auto;
        &:hover, &:focus {
            background-color: var(--#{$prefix}secondary);
        }
    }
 }